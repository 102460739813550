import { AllEventTypes, IDType, LanguageISO, LogMessage, MatchChanges } from "../config/types";
import {
  AdCreate,
  AdministrationService,
  AdPatch,
  AdService,
  ApiError,
  AreaOfInterest,
  AreaOfInterestService,
  AuditLogService,
  AuthenticationService,
  AuthResponse,
  BlogCreate,
  BlogPatch,
  BlogService,
  Body_send_notification_to_user_notification_user_post,
  CancelablePromise,
  CardTypeEnum,
  ChanceTypeEnum,
  EventService,
  ExportService,
  FeedService,
  GoalEventCreate,
  GoalEventPatch,
  GoalTypeEnum,
  GptMatchList,
  GptService,
  HomeMatch,
  ImportService,
  LanguageLocaleEnum,
  LanguageService,
  League,
  LeagueCategoryService,
  type LeagueCheck,
  LeagueCreate,
  LeagueImport_Input,
  LeagueMatchCheck,
  LeagueMatchImport_Input,
  LeaguePatch,
  LeagueService,
  LineupService,
  LocationService,
  MatchDayService,
  MatchLeague,
  MatchLeagueCreateNew,
  MatchLeaguePatch,
  MatchLocationEnum,
  MatchService,
  MatchTournamentGroup,
  MatchTournamentGroupCreate,
  MatchTournamentGroupPatch,
  MatchTournamentStage,
  MatchTournamentStageCreate,
  MatchTournamentStagePatch,
  MediaCrop,
  MediaService,
  MonitoringService,
  NotificationService,
  OpenAPI,
  OutService,
  PlayerCreate,
  PlayerPatchAdmin,
  PlayerPositionEnum,
  PlayerService,
  PostService,
  PunishmentCreate,
  PunishmentService,
  RegionService,
  type ScopeEnum,
  SearchService,
  SportEnum,
  Squad,
  SquadCheck,
  SquadImport_Input,
  SquadList,
  SquadService,
  StandingService,
  StatisticKeyEnum,
  TaskService,
  Team,
  type TeamCheck,
  TeamCreate,
  TeamImport_Input,
  TeamListElement,
  TeamPatch,
  TeamService,
  Tournament,
  TournamentCreate,
  TournamentGroup,
  TournamentGroupCreate,
  TournamentGroupPatch,
  TournamentGroupStanding,
  TournamentPatch,
  TournamentSecretPatch,
  TournamentService,
  TournamentStage,
  TournamentStageCreate,
  TournamentStagePatch,
  TournamentTeam,
  TournamentTeamCreate,
  TransferService,
  User,
  UserCreate,
  UserMediaService,
  UserService
} from "./api";
import { dayToNumber, errorLogging, formatDate, MINUTE_IN_MS } from "../config/utils";
import { AGB_LEVEL, AMOUNT_MATCHDAYS, PROD_ENDPOINT } from "../config/constants";
import { addLog } from "../config/logger";

class Client {
  public static token: string | undefined = undefined;

  private static JWT_STORAGE = "jwt_token";

  private static JWT_REFRESH_STORAGE = "jwt_refresh_token";

  private static refreshToken: string | undefined = undefined;

  private static __instance: Client | null = null;

  private endpoint = `https://${PROD_ENDPOINT}`;
  private eventTypes: AllEventTypes | undefined = undefined;

  private constructor(endpoint: string) {
    this.endpoint = `https://${endpoint}`;
    OpenAPI.TOKEN = Client.token;
    OpenAPI.BASE = this.endpoint;
  }

  static getInstance(endpoint?: string): Client {
    if (Client.__instance === null) {
      if (!endpoint) endpoint = PROD_ENDPOINT;
      errorLogging(`first client call with ${endpoint}`);
      Client.token = Client.getTokenAS();
      Client.refreshToken = Client.getRefreshTokenAS();
      Client.__instance = new Client(endpoint as string);
    }
    return Client.__instance;
  }

  public static getTokenAS(): string | undefined {
    return window.localStorage.getItem(Client.JWT_STORAGE) ?? undefined;
  }

  public static getRefreshTokenAS(): string | undefined {
    return window.localStorage.getItem(Client.JWT_REFRESH_STORAGE) ?? undefined;
  }

  public static setToken(
    response:
      | AuthResponse
      | {
      refresh_token: string;
      access_token: string;
    }
  ) {
    Client.token = response.access_token;
    Client.refreshToken = response.refresh_token;
    window.localStorage.setItem(Client.JWT_STORAGE, response.access_token);
    window.localStorage.setItem(
      Client.JWT_REFRESH_STORAGE,
      response.refresh_token
    );
    OpenAPI.TOKEN = Client.token;
  }

  public static removeToken() {
    Client.token = undefined;
    Client.refreshToken = undefined;
    window.localStorage.removeItem(Client.JWT_STORAGE);
    window.localStorage.removeItem(Client.JWT_REFRESH_STORAGE);
    OpenAPI.TOKEN = undefined;
    return;
  }


  getEndpoint(): string {
    return this.endpoint.replace("https://", "");
  }

  public setLang(lang: LanguageISO) {
    OpenAPI.HEADERS = { "User-Lang": lang };
  }

  public telAvailable(tel: string) {
    return UserService.telAvailableUserTelAvailableTelGet(tel);
  }

  public registerPatch(
    username: string,
    givenName: string,
    familyName: string,
    email: string,
    password: string,
    tel: string,
    newsletter: boolean
  ) {
    return new CancelablePromise<User>((resolve, reject) => {
      UserService.registerUserUserRegisterPost({
        familyName,
        email,
        password,
        givenName,
        tel,
        agbLevel: AGB_LEVEL,
        newsletter
      }).then((response) => {
        Client.setToken(response);
        UserService.patchUserUserPatch({ username }).then(
          (response2) => {
            resolve(response2);
          },
          () => {
            resolve(response.user);
          }
        );
      }, reject);
    });
  }

  public logout() {
    return Client.removeToken();
  }

  me() {
    return new CancelablePromise<User>((resolve, reject) => {
      UserService.getUserMeUserMeGet().then((user) => {
        if (user) {
          resolve(user);
        } else {
          // This is a very rare error and can only happen in DEV I think
          const error: ApiError = {
            body: "Unauthorized",
            name: "ApiError",
            statusText: "Unauthorized",
            url: "/user/me",
            message: "Unauthorized",
            status: 401,
            request: { method: "GET", url: "/user/me" }
          };
          reject(error);
        }
      }, reject);
    });
  }

  public loggedIn() {
    return typeof OpenAPI.TOKEN !== "undefined";
  }

  public authorize(email: string, password: string): CancelablePromise<User> {
    return new CancelablePromise<User>((resolve, reject) => {
      AuthenticationService.secureLoginWithEmailPasswordAuthSecureLoginPost({
        email,
        password
      }).then((response) => {
        Client.setToken(response);
        resolve(response.user);
      }, reject);
    });
  }

  pwReset(emailOrTel: string, password: string, hash: string) {
    return new CancelablePromise<User>((resolve, reject) => {
      AuthenticationService.confirmPasswordForgotAuthPasswordForgotConfirmEmailOrTelPost(
        emailOrTel,
        { password, code: hash }
      ).then((response) => {
        Client.setToken(response);
        resolve(response.user);
      }, reject);
    });
  }

  refreshToken(): Promise<User> {
    return new Promise((resolve, reject) => {
      OpenAPI.TOKEN = Client.refreshToken;
      AuthenticationService.refreshAuthRefreshPost().then(
        (response) => {
          Client.setToken(response);
          resolve(response.user);
        },
        (error) => {
          Client.removeToken();
          reject(error);
        }
      );
    });
  }

  register(body: UserCreate): CancelablePromise<User> {
    return new CancelablePromise<User>((resolve, reject) => {
      Client.removeToken();
      UserService.createUserUserPost(body).then((response) => {
        Client.setToken(response);
        resolve(response.user);
      }, (err) => {
        reject(err);
      });

    });
  }

  importMatches(matches: LeagueMatchCheck[]) {
    return ImportService.checkLeagueMatchImportImportCheckMatchPut(matches);
  }

  startImportMatchesTask(matches: LeagueMatchImport_Input[]) {
    return ImportService.startLeagueMatchImportImportMatchPost(matches);
  }

  importPlayers(players: SquadCheck[]) {
    return ImportService.checkSquadImportImportCheckSquadPut(players);
  }

  startImportPlayersTask(players: SquadImport_Input[]) {
    return ImportService.startSquadImportImportSquadPost(players);
  }

  importTeam(teams: TeamCheck[]) {
    return ImportService.checkTeamImportImportCheckTeamPut(teams);
  }

  startImportTeam(teams: TeamImport_Input[]) {
    return ImportService.startTeamImportImportTeamPost(teams);
  }

  importLeague(leagues: LeagueCheck[]) {
    return ImportService.checkLeagueImportImportCheckLeaguePut(leagues);
  }

  startImportLeague(leagues: LeagueImport_Input[]) {
    return ImportService.startLeagueImportImportLeaguePost(leagues);
  }

  getTask(id: string) {
    return TaskService.getTaskTaskPost(id);
  }

  emailAvailable(email: string) {
    return UserService.emailAvailableUserEmailAvailableEmailGet(email);
  }

  public removePlayerFormTeam(playerId: IDType, teamId: IDType) {
    return TeamService.removePlayerFromTeamTeamTeamIdRemovePlayerIdPost(playerId, teamId);
  }

  public getPLayerPositions() {
    return PlayerService.getPlayerPositionsPlayerPlayerPositionGet();
  }

  public changeAoi(areaOfInterestId: IDType) {
    return UserService.patchUserUserPatch({ areaOfInterestId });
  }

  getLeaguesOfAoi(id: IDType, sports: SportEnum): CancelablePromise<League[]> {
    return LeagueService.getLeaguesLeagueGet(id, sports);
  }

  createPunishment(body: PunishmentCreate) {
    return PunishmentService.createPunishmentPunishmentPost(body);
  }

  getLeagues(aoiId: string): CancelablePromise<League[]> {
    if (aoiId.startsWith("aoi_")) {
      const id = parseInt(aoiId.replace("aoi_", ""), 10);
      return SearchService.searchLeagueSearchLeagueQueryGet(" ", 100, 0, id);
    } else if (aoiId.startsWith("region_")) {
      const id = parseInt(aoiId.replace("region_", ""), 10);
      return SearchService.searchLeagueSearchLeagueQueryGet(" ", 100, 0, undefined, undefined, id);
    } else {
      return SearchService.searchLeagueSearchLeagueQueryGet(" ", 100, 0);
    }
  }

  changeMatchTeam(matchId: IDType, teamFromId: IDType, teamToId: IDType) {
    return MatchService.changeTeamLeagueMatchMatchesV2LeagueChangeTeamMatchIdPost(matchId, teamFromId, teamToId);
  }

  reorderLeagues(aoi: IDType, order: IDType[]) {
    return LeagueService.setLeagueOrderLeagueSetOrderPost(aoi, order);
  }

  getLeague(id: IDType): CancelablePromise<League> {
    return LeagueService.getLeagueByIdLeagueLeagueIdGet(id);
  }

  getLeagueCategories() {
    return LeagueCategoryService.getLeagueCategoriesLeagueCategoryGet();
  }

  getTeamsOfLeague(id: IDType): CancelablePromise<TeamListElement[]> {
    return TeamService.getTeamsTeamGet(id);
  }

  getTeam(id: IDType): CancelablePromise<Team> {
    return TeamService.getTeamByIdTeamTeamIdGet(id);
  }

  patchTeam(id: IDType, body: TeamPatch) {
    return TeamService.patchTeamTeamTeamIdPatch(id, body);
  }

  patchTeamEmblem(id: IDType, file: File) {
    return new CancelablePromise<Team>((resolve, reject) => {
      MediaService.uploadImageMediaImagePost({ file }, true).then((image) => {
        TeamService.patchTeamTeamTeamIdPatch(id, { imageId: image.id }).then(resolve, reject);
      }, reject);
    });
  }

  createTeam(body: TeamCreate) {
    return TeamService.createTeamTeamPost(body);
  }

  getLanguages() {
    return LanguageService.getLanguagesLanguageGet();
  }

  getAois() {
    return AreaOfInterestService.getAreaOfInterestsAreaOfInterestGet();
  }

  getRegions() {
    return RegionService.getRegionsRegionGet();
  }

  public loginWithSMS(identifier: { email: string } | { tel: string }) {
    if ("tel" in identifier) {
      return AuthenticationService.loginWithTelAndSmsAuthLoginWithTelAndSmsTelPost(
        identifier.tel
      );
    }
    return AuthenticationService.loginWithMailAndSmsAuthLoginWithMailAndSmsMailPost(
      identifier.email
    );
  }

  getOut(url: string) {
    return OutService.getOutTargetOutTargetGet(url);
  }

  getOutCount(url: string) {
    return OutService.getOutCountOutCountGet(url);
  }

  getMatch(id: IDType) {
    return MatchService.getMatchLeagueMatchesV2LeagueMatchIdGet(id);
  }

  getPlayersOfTeam(id: IDType) {
    return PlayerService.getPlayersByTeamPlayerTeamTeamIdGet(id);
  }

  getGptAmountMatches() {
    return GptService.getAmountMatchesTeamFactsGptAmoutMatchesTeamFactsGet();
  }

  getGptTemp() {
    return GptService.getTemperatureGptTemperatureGet();
  }

  getGptModel() {
    return GptService.getModelGptModelGet();
  }

  getGptMaxTokens() {
    return GptService.getMaxTokensGptMaxTokensGet();
  }

  getGptSysData(teamId: number, amountMatches: number) {
    return GptService.getDeveloperContentTeamFactsGptDeveloperContentTeamFactsTeamIdGet(teamId, amountMatches);
  }

  getGptTopG() {
    return GptService.getTopPGptTopPGet();
  }

  getGPT(
    teamId: number,
    matchDay: number,
    leagueId: number, amountMatches: number) {
    return new CancelablePromise<{
      matches: GptMatchList,
      topP: number,
      devData: string,
      maxTokens: number,
      model: string,
      temp: number
    }>((resolve, reject) => {
      GptService.getUserContentTeamFactsGptUserContentTeamFactsTeamIdGet(teamId, matchDay, leagueId, amountMatches).then((matches) => {
        GptService.getTopPGptTopPGet().then((topP) => {
          GptService.getTemperatureGptTemperatureGet().then((temp) => {
            GptService.getDeveloperContentTeamFactsGptDeveloperContentTeamFactsTeamIdGet(teamId, amountMatches).then((devData) => {
              GptService.getMaxTokensGptMaxTokensGet().then((maxTokens) => {
                GptService.getModelGptModelGet().then((model) => {
                  resolve({
                    model, maxTokens, matches, topP, devData, temp
                  });
                }, reject);
              }, reject);
            }, reject);
          }, reject);
        }, reject);
      }, reject);
    });

  }

  gptManualFact(
    temperature: number,
    maxTokens: number,
    userMessage: string,
    systemMessage: string,
    model: string,
    topP: number) {
    return GptService.manualRequestGptManualRequestPost({
      temperature,
      max_tokens: maxTokens,
      user_message: userMessage,
      system_message: systemMessage,
      model,
      top_p: topP
    });
  }

  getLog(
    userId?: (number | null),
    method?: ("POST" | "PUT" | "PATCH" | "DELETE" | null),
    action?: (string | null),
    page = 1,
    pageSize = 50) {
    return AuditLogService.queryLogsAuditLogGet(userId, method, action, page, pageSize);
  }

  patchPlayer(
    teamId: IDType,
    playerId: IDType,
    patch: PlayerPatchAdmin,
    position?: PlayerPositionEnum
  ) {
    return new CancelablePromise<SquadList>((resolve, reject) => {
      PlayerService.patchPlayerAdminPlayerAdminPlayerIdPatch(playerId, patch).then(() => {
        if (position) {
          TeamService.changePlayerPositionTeamTeamIdChangePositionPositionPlayerIdPost(playerId, teamId, position).then(() => {
            SquadService.getSquadSquadTeamIdGet(teamId).then(resolve, reject);
          });
        } else {
          SquadService.getSquadSquadTeamIdGet(teamId).then(resolve, reject);
        }
      }, reject);
    });
  }

  addPlayerToTeam(playerId: IDType, teamId: IDType, pos: PlayerPositionEnum) {
    return new CancelablePromise<Squad>((resolve, reject) => {
      TeamService.addPlayerToTeamTeamTeamIdAddPlayerIdPost(playerId, teamId, pos).then(() => {
        SquadService.getSingleSquadSquadTeamTeamIdPlayerPlayerIdGet(teamId, playerId).then(resolve, reject);
      });
    });
  }

  transferPlayerToTeam(playerId: IDType, oldTeamId: IDType, teamId: IDType, pos: PlayerPositionEnum) {
    return new CancelablePromise<Squad>((resolve, reject) => {
      TransferService.transferPlayerTransferPlayerIdFromOldTeamIdToNewTeamIdPost(playerId, oldTeamId, teamId, pos).then(() => {
        SquadService.getSingleSquadSquadTeamTeamIdPlayerPlayerIdGet(teamId, playerId).then(resolve, reject);
      });
    });
  }

  createPlayer(
    teamId: IDType,
    body: PlayerCreate
  ) {
    return new CancelablePromise<Squad>((resolve, reject) => {
      PlayerService.createPlayerPlayerPost(body).then((player) => {
        SquadService.getSingleSquadSquadTeamTeamIdPlayerPlayerIdGet(teamId, player.id).then(resolve, reject);
      }, reject);
    });
  }

  searchPlayer(givenName: string, familyName: string, aoiId?: string) {
    if (aoiId) {
      if (aoiId.startsWith("aoi_")) {
        const id = parseInt(aoiId.replace("aoi_", ""), 10);
        return SearchService.searchPlayerSearchPlayerQueryGet(`${givenName} ${familyName}`, 10, 0, true, id);
      } else if (aoiId.startsWith("region_")) {
        const id = parseInt(aoiId.replace("region_", ""), 10);
        return SearchService.searchPlayerSearchPlayerQueryGet(`${givenName} ${familyName}`, 10, 0, true, undefined, id);
      }
    }
    return SearchService.searchPlayerSearchPlayerQueryGet(`${givenName} ${familyName}`, 10, 0, true);
  }

  postCardEvent(
    matchID: IDType,
    teamId: IDType,
    cardTypeKey: CardTypeEnum,
    minute: number,
    playerId?: IDType
  ) {
    return EventService.addCardEventEventCardMatchIdPost(
      matchID,
      minute === -1
        ? { cardTypeKey, teamId, playerId }
        : {
          cardTypeKey,
          minute,
          teamId,
          playerId
        }
    );
  }

  postChanceEvent(
    matchID: IDType,
    teamId: IDType,
    chanceTypeKey: ChanceTypeEnum,
    minute: number,
    playerId?: IDType
  ) {
    return EventService.addChanceEventEventChanceMatchIdPost(
      matchID,
      minute === -1
        ? { teamId, playerId, chanceTypeKey }
        : {
          minute,
          teamId,
          playerId,
          chanceTypeKey
        }
    );
  }

  postChangeEvent(
    matchID: IDType,
    teamId: IDType,
    playerOutId: IDType,
    minute: number,
    injured: boolean,
    playerInId: IDType
  ) {
    return EventService.addChangeEventEventChangeMatchIdPost(
      matchID,
      minute === -1
        ? { teamId, playerId: playerOutId, injured, playerInId }
        : {
          minute,
          teamId,
          playerId: playerOutId,
          injured,
          playerInId
        }
    );
  }

  patchCardEvent(
    eventId: IDType,
    minute?: number,
    playerId?: IDType,
    cardTypeKey?: CardTypeEnum
  ) {
    return EventService.patchCardEventEventCardEventIdPatch(eventId, {
      cardTypeKey,
      minute,
      playerId
    });
  }

  patchChanceEvent(
    eventId: IDType,
    minute?: number,
    chanceTypeKey?: ChanceTypeEnum,
    playerId?: IDType
  ) {
    return EventService.patchChanceEventEventChanceEventIdPatch(eventId, {
      minute,
      playerId,
      chanceTypeKey
    });
  }

  patchChangeEvent(
    eventId: IDType,
    minute?: number,
    playerOutId?: IDType,
    injured?: boolean,
    playerInId?: IDType
  ) {
    return EventService.patchChangeEventEventChangeEventIdPatch(eventId, {
      minute,
      playerId: playerOutId,
      injured,
      playerInId
    });
  }

  patchGoalEvent(
    eventId: IDType,
    minute?: number,
    playerId?: IDType,
    assistId?: IDType,
    goalTypeKey?: GoalTypeEnum
  ) {
    return EventService.patchGoalEventEventGoalEventIdPatch(eventId, {
      minute,
      playerId,
      assistId,
      goalTypeKey: goalTypeKey ?? null
    });
  }

  getAllEventTypes(): CancelablePromise<AllEventTypes> {
    return new CancelablePromise<AllEventTypes>((resolve, reject) => {
      if (this.eventTypes) {
        resolve(this.eventTypes);
        return;
      }
      EventService.getGoalTypesEventTypeGoalGet().then(soccerGoal => {
        EventService.getChanceTypesEventTypeChanceGet().then(chance => {
          EventService.getCardTypesEventTypeCardGet().then(soccerCard => {
            EventService.getGoalTypesEventTypeGoalGet(SportEnum.HOCKEY).then(
              hockeyGoal => {
                EventService.getCardTypesEventTypeCardGet(
                  SportEnum.HOCKEY
                ).then(hockeyCard => {
                  this.eventTypes = {
                    chance,
                    goals: soccerGoal,
                    card: soccerCard,
                    cardHockey: hockeyCard,
                    goalsHockey: hockeyGoal
                  };
                  resolve(this.eventTypes);
                }, reject);
              },
              reject
            );
          }, reject);
        }, reject);
      }, reject);
    });
  }

  postGoalEvent(
    matchID: IDType,
    teamId: IDType,
    goal1: number,
    goal2: number,
    minute?: number,
    playerId?: IDType,
    assistId?: IDType,
    goalTypeKey?: GoalTypeEnum,
    ignoreChecks?: boolean
  ) {
    return EventService.addGoalEventEventGoalMatchIdPost(
      matchID,
      {
        minute,
        teamId,
        playerId,
        assistId,
        goal1,
        goal2,
        goalTypeKey
      },
      ignoreChecks
    );
  }

  deleteEvent(id: IDType) {
    return EventService.deleteEventEventEventIdDelete(id);
  }

  getLineup(matchId: IDType, team: MatchLocationEnum) {
    return LineupService.getLineupByMatchAndTeamLineupMatchMatchIdTeamGet(
      matchId,
      team
    );
  }

  createNews(news: BlogCreate) {
    return BlogService.createBlogBlogPost(news);
  }

  getNews(id: IDType) {
    return BlogService.getBlogBlogBlogIdGet(id);
  }

  deleteNews(id: IDType) {
    return BlogService.deleteBlogBlogBlogIdDelete(id);
  }

  patchNews(id: IDType, news: BlogPatch) {
    return BlogService.patchBlogBlogPatch(id, news);
  }

  createMatch(leagueId: IDType, match: MatchLeagueCreateNew) {
    return MatchService.createMatchLeagueMatchesV2LeagueLeagueIdPost(
      leagueId,
      match
    );
  }

  patchMatch(id: IDType, match: MatchLeaguePatch) {
    return MatchService.patchLeagueMatchMatchesV2LeagueMatchIdPatch(id, match);
  }

  patchMatchExt(patch: MatchChanges) {
    return new CancelablePromise<MatchLeague>((resolve, reject) => {
      const promises: CancelablePromise<MatchLeague>[] = [];
      const { toTeam1, toTeam2, fromTeam1, fromTeam2, ...patchMatch } = patch.patch;
      if (fromTeam1 && toTeam1) {
        promises.push(MatchService.changeTeamLeagueMatchMatchesV2LeagueChangeTeamMatchIdPost(patch.id, fromTeam1, toTeam1));
      }
      if (fromTeam2 && toTeam2) {
        promises.push(MatchService.changeTeamLeagueMatchMatchesV2LeagueChangeTeamMatchIdPost(patch.id, fromTeam2, toTeam2));
      }
      promises.push(MatchService.patchLeagueMatchMatchesV2LeagueMatchIdPatch(patch.id, patchMatch));
      Promise.allSettled(promises).then((results) => {
        let match: MatchLeague | undefined;
        results.forEach(result => {
          if (result.status === "fulfilled" && !match) {
            match = result.value;
          }
        });
        if (match) {
          resolve(match);
        } else {
          reject("inputError");
        }
      });
    });
  }

  patchMatches(changes: MatchChanges[]) {
    return new CancelablePromise<MatchLeague[]>((resolve) => {
      const promises: CancelablePromise<MatchLeague>[] = [];
      changes.forEach(change => promises.push(client().patchMatchExt(change)));
      Promise.allSettled(promises).then((results) => {
        const matches: MatchLeague[] = [];
        results.forEach(result => {
          if (result.status === "fulfilled") {
            matches.push(result.value);
          }
        });
        resolve(matches);
      });
    });
  }

  patchBulk(ids: IDType[], match: MatchLeaguePatch) {
    return new CancelablePromise<MatchLeague[]>((resolve, reject) => {
      const promises: CancelablePromise<MatchLeague>[] = [];
      ids.forEach((id) => {
        promises.push(MatchService.patchLeagueMatchMatchesV2LeagueMatchIdPatch(id, match));
      });
      Promise.allSettled(promises).then((results) => {
        const retval: MatchLeague[] = [];
        results.forEach(res => {
          if (res.status === "fulfilled") {
            retval.push(res.value);
          }
        });
        resolve(retval);
      }, reject);
    });
  }

  patchAd(id: IDType, ad: AdPatch) {
    return AdService.patchAdAdAdIdPatch(id, ad);
  }

  createAd(ad: AdCreate) {
    return AdService.createAdAdPost(ad);
  }

  getAd(id: IDType) {
    return AdService.getAdAdAdIdGet(id);
  }

  patchLeague(id: IDType, league: LeaguePatch) {
    return LeagueService.patchLeagueLeagueLeagueIdPatch(id, league);
  }

  createLeague(league: LeagueCreate) {
    return LeagueService.createLeagueLeaguePost(league);
  }

  deleteLeague(id: IDType) {
    return LeagueService.deleteLeagueLeagueLeagueIdDelete(id);
  }

  searchLeagues(q: string, offset: number, limit: number, aoiId?: string, active?: boolean) {
    if (aoiId) {
      if (aoiId.startsWith("aoi_")) {
        const id = parseInt(aoiId.replace("aoi_", ""), 10);
        return SearchService.searchLeagueSearchLeagueQueryGet(q, limit, offset, id, active);
      } else if (aoiId.startsWith("region_")) {
        const id = parseInt(aoiId.replace("region_", ""), 10);
        return SearchService.searchLeagueSearchLeagueQueryGet(q, limit, offset, undefined, active, id);
      }
    }
    return SearchService.searchLeagueSearchLeagueQueryGet(q, limit, offset, undefined, active);
  }

  searchTournaments(q: string, offset: number, limit: number, cup?: boolean) {
    return SearchService.searchTournamentSearchTournamentQueryGet(q, limit, offset, cup);
  }

  searchTeams(q: string, offset: number, limit: number, leagueId?: IDType, aoiId?: string, active?: boolean) {
    if (aoiId) {
      if (aoiId.startsWith("aoi_")) {
        const id = parseInt(aoiId.replace("aoi_", ""), 10);
        return SearchService.searchTeamSearchTeamQueryGet(
          q,
          limit,
          offset,
          leagueId,
          id, active,
          undefined);
      } else if (aoiId.startsWith("region_")) {
        const id = parseInt(aoiId.replace("region_", ""), 10);
        return SearchService.searchTeamSearchTeamQueryGet(
          q,
          limit,
          offset,
          leagueId,
          undefined, active,
          id);
      }
    }
    return SearchService.searchTeamSearchTeamQueryGet(
      q,
      limit,
      offset,
      leagueId,
      undefined,
      active
    );

  }

  searchUser(q: string, offset: number, limit: number,
             scopes?: (Array<ScopeEnum> | null),
             areaOfInterestIds?: (Array<number> | null),
             insidersOfTeams?: (Array<number> | null),
             isInsider?: (boolean | null),
             isPlayer?: (boolean | null),
             languages?: (Array<string> | null),
             anonymous?: (boolean | null)) {
    return SearchService.searchUserSearchUserQueryGet(q, limit, offset, scopes, areaOfInterestIds, insidersOfTeams, isInsider, isPlayer, languages, anonymous);
  }

  countUser(q: string,
            scopes?: (Array<ScopeEnum> | null),
            areaOfInterestIds?: (Array<number> | null),
            insidersOfTeams?: (Array<number> | null),
            isInsider?: (boolean | null),
            isPlayer?: (boolean | null),
            languages?: (Array<string> | null),
            anonymous?: (boolean | null)) {
    return NotificationService.getUserNotificationCountNotificationUserNotificationCountGet(q, scopes, areaOfInterestIds, insidersOfTeams, isInsider, isPlayer, languages, anonymous);
  }

  sendNotification(body: Body_send_notification_to_user_notification_user_post) {
    return NotificationService.sendNotificationToUserNotificationUserPost(body);
  }

  sendNotificationMany(body: Body_send_notification_to_user_notification_user_post, q: string,
                       scopes?: (Array<ScopeEnum> | null),
                       areaOfInterestIds?: (Array<number> | null),
                       insidersOfTeams?: (Array<number> | null),
                       isInsider?: (boolean | null),
                       isPlayer?: (boolean | null),
                       languages?: (Array<string> | null),
                       anonymous?: (boolean | null)) {
    return NotificationService.sendNotificationsToUserSearchNotificationUserNotificationsPost(q, body, scopes, areaOfInterestIds, insidersOfTeams, isInsider, isPlayer, languages, anonymous);
  }

  searchAds(q: string, offset: number, limit: number) {
    return SearchService.searchAdSearchAdQueryGet(q, limit, offset);
  }

  searchNews(q: string, offset: number, limit: number, deleted?: boolean, aoi?: IDType) {
    return SearchService.searchBlogSearchBlogQueryGet(q, limit, offset, deleted, aoi);
  }

  deleteAd(id: IDType) {
    return AdService.deleteAdAdAdIdDelete(id);
  }

  searchLocations(q: string, offset: number, limit: number) {
    return SearchService.searchLocationSearchLocationQueryGet(q, limit, offset);
  }

  getUserStats() {
    return MonitoringService.getUsersPerAreaOfInterestMonitoringUsersPerAreaOfInterestGet();
  }

  searchMatches(q: string, offset: number, limit: number, aoiId?: string,
                leagueId?: IDType,
                teamId?: IDType,
                matchDay?: number,
                dateFrom?: string,
                dateTo?: string, matchDays?: number[]) {
    if (aoiId) {
      if (aoiId.startsWith("aoi_")) {
        const id = parseInt(aoiId.replace("aoi_", ""), 10);
        return SearchService.searchMatchSearchMatchQueryGet(
          q,
          id,
          leagueId,
          teamId,
          matchDay,
          dateFrom,
          dateTo,
          limit,
          offset,
          undefined,
          matchDays
        );
      } else if (aoiId.startsWith("region_")) {
        const id = parseInt(aoiId.replace("region_", ""), 10);

        return SearchService.searchMatchSearchMatchQueryGet(
          q,
          undefined,
          leagueId,
          teamId,
          matchDay,
          dateFrom,
          dateTo,
          limit,
          offset,
          id,
          matchDays
        );
      }
    }
    return SearchService.searchMatchSearchMatchQueryGet(
      q,
      undefined,
      leagueId,
      teamId,
      matchDay,
      dateFrom,
      dateTo,
      limit,
      offset,
      undefined,
      matchDays
    );
  }

  bulkDeleteMatch(ids: IDType[]) {
    return MatchService.bulkDeleteMatchesMatchesV2BulkDelete(ids);
  }

  deleteMatch(id: IDType) {
    return MatchService.deleteMatchMatchesV2MatchIdDelete(id);
  }

  imageLink(aspectRatio: number, cropData: MediaCrop) {
    return UserMediaService.requestImageUploadUserMediaImagePost(
      aspectRatio,
      cropData
    );
  }

  maxVideoLength() {
    return UserMediaService.getMaxUploadLengthSecondsUserMediaMaxUploadLengthSecondsGet();
  }

  tusVideoLink(
    aspectRatio: number,
    uploadLength: number,
    name: string,
    cropData: MediaCrop
  ) {
    return UserMediaService.requestTusUploadUserMediaTusPost(
      aspectRatio,
      uploadLength,
      cropData,
      name
    );
  }

  deleteVideoCloudflare(id: string) {
    return UserMediaService.deleteVideoUserMediaVideoVideoIdDelete(id);
  }

  deleteImageCloudflare(id: string) {
    return UserMediaService.deleteImageUserMediaImageImageIdDelete(id);
  }

  clearCache() {
    return AdministrationService.clearCacheAdminClearCachePost();
  }

  generateReport(aoiId: number, matchday?: number) {
    return ExportService.exportPlayerScoreExportPlayerScoreAreaOfInterestIdPost(
      aoiId,
      matchday
    );
  }

  getMatchDays(
    aois: IDType[],
    sports: SportEnum
  ): CancelablePromise<Date[]> {
    return new CancelablePromise<Date[]>((resolve) => {
      const promises: CancelablePromise<string[]>[] = [];
      aois.forEach(aoi => {
        promises.push(MatchDayService.getMatchDatesTodayMatchDayTodayAmountDaysGet(
          AMOUNT_MATCHDAYS,
          aoi,
          undefined,
          undefined,
          sports
        ));
      });
      Promise.allSettled(promises).then(results => {
        let final: Date[] = [];
        results.forEach(result => {
          if (result.status === "fulfilled") {
            final = final.concat(result.value.map(m => new Date(m))).filter((val, idx, arr) => arr.findIndex(innerVal => dayToNumber(val) === dayToNumber(innerVal)) === idx).sort((a, b) => a.getTime() - b.getTime());
          }
        });
        resolve(final);
      });
    });
  }

  getStandings(id: IDType) {
    return StandingService.getStandingByLeagueStandingLeagueAllLeagueIdGet(
      id
    );
  }

  getFeed(aoiId: IDType, offset: number, limit: number) {
    return FeedService.getRegionFeedFeedRegionRegionIdGet(aoiId, offset, limit);
  }


  deletePost(id: IDType) {
    return PostService.deletePostPostPostIdDelete(id);
  }

  getPunishments(id: IDType) {
    return PunishmentService.getPunishmentsByLeaguePunishmentLeagueLeagueIdGet(id);
  }

  getLeagueMatchesOfToday(leagueId: IDType) {
    return MatchService.getMatchesByLeagueAndDateMatchesV2LeagueLeagueIdDateMatchDateGet(leagueId, formatDate(new Date(), LanguageLocaleEnum.EN, true, false, true));
  }

  getDownloadLink(id: string) {
    return MediaService.downloadImageMediaDownloadMediaIdGet(id);
  }

  getLeagueMatchesOfDay(
    date: Date,
    aois: IDType[],
    sports: SportEnum
  ): CancelablePromise<HomeMatch[]> {
    return new CancelablePromise<HomeMatch[]>((resolve) => {
      const promises: CancelablePromise<HomeMatch[]>[] = [];
      aois.forEach(aoi => {
        promises.push(MatchService.getHomeMatchesMatchesV2HomeGet(
          aoi,
          formatDate(date, LanguageLocaleEnum.DE, true, false, true),
          sports
        ));
      });
      Promise.allSettled(promises).then(results => {
        let final: HomeMatch[] = [];
        results.forEach(result => {
          if (result.status === "fulfilled") {
            final = final.concat(result.value).filter((val, idx, arr) => arr.findIndex(innerVal => innerVal.leagueTournamentId === val.leagueTournamentId) === idx);
          }
        });
        resolve(final);
      });
    });
  }

  addTeamsToLeague(leagueId: IDType, teamIds: IDType[]) {
    return new CancelablePromise<League>(async (resolve, reject) => {
      if (teamIds.length !== 0) {
        const promises: Promise<League>[] = [];
        teamIds.forEach((teamId) => {
          promises.push(
            LeagueService.addTeamToLeagueLeagueAddTeamPost(leagueId, teamId)
          );
        });
        Promise.allSettled(promises).then((results) => {
          let league: League | undefined;
          results.forEach((res) => {
            if (res.status === "fulfilled") league = res.value;
          });
          if (league) resolve(league);
          else reject("No team added");
        });
      }
    });
  }

  addLeaguesToTeam(teamId: IDType, leagueIds: IDType[]) {
    return new CancelablePromise<Team>(async (resolve, reject) => {
      if (leagueIds.length !== 0) {
        const promises: Promise<League>[] = [];
        leagueIds.forEach((leagueId) => {
          promises.push(
            LeagueService.addTeamToLeagueLeagueAddTeamPost(leagueId, teamId)
          );
        });
        Promise.allSettled(promises).then(() => {
          TeamService.getTeamByIdTeamTeamIdGet(teamId).then(resolve, reject);
        });
      }
    });
  }

  removeTeamFromLeague(leagueId: IDType, teamIds: IDType[]) {
    return new CancelablePromise<League>(async (resolve, reject) => {
      if (teamIds.length !== 0) {
        const promises: Promise<League>[] = [];
        teamIds.forEach((teamId) => {
          promises.push(
            LeagueService.removeTeamFromLeagueLeagueRemoveTeamPost(
              leagueId,
              teamId
            )
          );
        });
        Promise.allSettled(promises).then((results) => {
          let league: League | undefined;
          results.forEach((res) => {
            if (res.status === "fulfilled") league = res.value;
          });
          if (league) resolve(league);
          else reject("No team removed");
        });
      }
    });
  }

  removeSingleTeamFromLeague(leagueId: IDType, teamId: IDType) {
    return LeagueService.removeTeamFromLeagueLeagueRemoveTeamPost(
      leagueId,
      teamId
    );
  }

  deleteTeam(id: IDType) {
    return TeamService.deleteTeamTeamTeamIdDelete(id);
  }

  getCup(id: IDType) {
    return TournamentService.getTournamentTournamentTournamentIdGet(id);
  }

  getCupStages(id: IDType) {
    return TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
      id
    );
  }

  getCupStandings(id: IDType) {
    return TournamentService.getTournamentGroupStandingsTournamentGroupStandingsTournamentIdGet(
      id
    );
  }

  getCupGroups(id: IDType) {
    return TournamentService.getTournamentGroupsTournamentGroupTournamentIdGet(
      id
    );
  }

  fetchCupTeam(teamId: IDType, cupId: IDType) {
    return TeamService.getTournamentTeamTeamTeamIdTournamentTournamentIdGet(
      teamId,
      cupId
    );
  }

  getCupMatches(cupId: IDType) {
    return MatchService.getMatchesByTournamentMatchesV2TournamentTournamentIdGet(
      cupId
    );
  }

  getCupMatch(matchId: IDType, isGroup: boolean) {
    if (isGroup) {
      return MatchService.getMatchTournamentGroupMatchesV2TournamentGroupMatchIdGet(
        matchId
      );
    }
    return MatchService.getMatchTournamentStageMatchesV2TournamentStageMatchIdGet(
      matchId
    );
  }

  getCupTopScorer(cupId: IDType) {
    return TournamentService.getTournamentStatisticsTournamentStatisticsTournamentIdGet(
      cupId,
      StatisticKeyEnum.PLAYER_MOST_GOALS
    );
  }

  patchCupPdf(cupId: IDType, doc: File) {
    const data = new FormData();
    const link = `${Client.getInstance().endpoint}/tournament/pdf/${cupId}`;
    data.append("pdf", doc);
    const msg: LogMessage = { Post: link, Body: data };
    addLog("req", msg);
    return fetch(link, {
      method: "PATCH",
      body: data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${Client.token}`
      }
    });
  }

  patchCup(cupId: IDType, cup: TournamentPatch, location?: string, doc?: File) {
    return new CancelablePromise<Tournament>((resolve, reject) => {
      if (location) {
        if (doc) {
          client()
            .patchCupPdf(cupId, doc)
            .then(() => {
              LocationService.createLocationLocationPost({
                name: { textDE: location, textEN: location, textIT: location },
                lat: 0,
                long: 0
              }).then((loc) => {
                TournamentService.patchTournamentTournamentTournamentIdPatch(
                  cupId,
                  {
                    ...cup,
                    locationId: loc.id
                  }
                ).then(resolve, reject);
              });
            }, reject);
        } else {
          LocationService.createLocationLocationPost({
            name: { textDE: location, textEN: location, textIT: location },
            lat: 0,
            long: 0
          }).then((loc) => {
            TournamentService.patchTournamentTournamentTournamentIdPatch(
              cupId,
              {
                ...cup,
                locationId: loc.id
              }
            ).then(resolve, reject);
          });
        }
      } else if (doc) {
        client()
          .patchCupPdf(cupId, doc)
          .then(() => {
            TournamentService.patchTournamentTournamentTournamentIdPatch(
              cupId,
              cup
            ).then(resolve, reject);
          }, reject);
      } else {
        TournamentService.patchTournamentTournamentTournamentIdPatch(
          cupId,
          cup
        ).then(resolve, reject);
      }
    });
  }

  patchCupTimes(cupId: IDType, cup: TournamentPatch, groupIds: IDType[]) {
    return new CancelablePromise<Tournament>((resolve, reject) => {
      const promises: CancelablePromise<TournamentGroup>[] = [];
      groupIds.forEach((id) => {
        promises.push(
          TournamentService.patchGroupTournamentGroupTournamentGroupIdPatch(
            id,
            {
              matchSectionAmount: cup.matchSectionAmount,
              matchSectionDurationMinutes: cup.matchSectionDurationMinutes,
              matchSectionPauseDurationMinutes:
              cup.matchSectionPauseDurationMinutes
            }
          )
        );
      });
      Promise.allSettled(promises).then(() => {
        TournamentService.patchTournamentTournamentTournamentIdPatch(
          cupId,
          cup
        ).then(resolve, reject);
      });
    });
  }

  patchCupSecrets(cupId: IDType, cup: TournamentSecretPatch) {
    return TournamentService.patchTournamentSecretsTournamentSecretsTournamentIdPatch(
      cupId,
      cup
    );
  }

  addCupPlayer(
    teamId: IDType,
    givenName: string,
    familyName: string,
    jersey: number | undefined
  ) {
    return new CancelablePromise<SquadList>((resolve, reject) => {
      PlayerService.createPlayerPlayerPost({
        mainTeamId: teamId,
        jersey,
        givenName,
        familyName,
        teamPositions: [{ teamId, position: PlayerPositionEnum.MIDFIELD }]
      }).then(() => {
        SquadService.getSquadSquadTeamIdGet(teamId).then(resolve, reject);
      }, reject);
    });
  }

  patchCupPlayer(
    teamId: IDType,
    playerId: IDType,
    givenName: string,
    familyName: string,
    jersey: number | undefined
  ) {
    return new CancelablePromise<SquadList>((resolve, reject) => {
      PlayerService.patchPlayerPlayerPlayerIdPatch(playerId, {
        jersey,
        givenName,
        familyName
      }).then(() => {
        SquadService.getSquadSquadTeamIdGet(teamId).then(resolve, reject);
      }, reject);
    });
  }

  deleteCupPlayer(teamId: IDType, playerId: IDType) {
    return new CancelablePromise<SquadList>((resolve, reject) => {
      PlayerService.deletePlayerPlayerPlayerIdDelete(playerId).then(() => {
        SquadService.getSquadSquadTeamIdGet(teamId).then(resolve, reject);
      }, reject);
    });
  }

  requestCupManager(cupId: IDType, code: string, teamId: IDType) {
    return UserService.addTournamentManagerToUserUserTournamentManagerTournamentIdPost(
      cupId,
      code,
      teamId
    );
  }

  requestCupAdmin(cupId: IDType, code: string) {
    return UserService.addTournamentAdminToUserUserTournamentAdminTournamentIdPost(
      cupId,
      code
    );
  }

  deleteCupEvent(id: IDType) {
    return EventService.deleteEventEventEventIdDelete(id);
  }

  addCupEvent(matchId: IDType, event: GoalEventCreate, ignoreChecks: boolean) {
    return EventService.addGoalEventEventGoalMatchIdPost(
      matchId,
      event,
      ignoreChecks
    );
  }

  patchCupEvent(id: IDType, event: GoalEventPatch) {
    return EventService.patchGoalEventEventGoalEventIdPatch(id, event);
  }

  getTournamentSecrets(id: IDType) {
    return TournamentService.getTournamentSecretsTournamentSecretsTournamentIdGet(
      id
    );
  }

  patchCupMatchStage(
    matchId: IDType,
    matchPatch: MatchTournamentStagePatch,
    location?: string
  ) {
    return new CancelablePromise<MatchTournamentStage>((resolve, reject) => {
      if (location) {
        LocationService.createLocationLocationPost({
          name: { textDE: location, textEN: location, textIT: location },
          lat: 0,
          long: 0
        }).then((loc) => {
          MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
            matchId,
            {
              ...matchPatch,
              locationId: loc.id
            }
          ).then(resolve, reject);
        }, reject);
      } else {
        MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
          matchId,
          matchPatch
        ).then(resolve, reject);
      }
    });
  }

  deleteCupMatchGroup(matchId: IDType, cupId: IDType) {
    return new CancelablePromise<TournamentGroup[]>((resolve, reject) => {
      MatchService.deleteMatchMatchesV2MatchIdDelete(matchId).then(() => {
        TournamentService.getTournamentGroupsTournamentGroupTournamentIdGet(
          cupId
        ).then(resolve, reject);
      }, reject);
    });
  }

  deleteCupMatchStage(matchId: IDType, cupId: IDType) {
    return new CancelablePromise<TournamentStage[]>((resolve, reject) => {
      MatchService.deleteMatchMatchesV2MatchIdDelete(matchId).then(() => {
        TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
          cupId
        ).then(resolve, reject);
      }, reject);
    });
  }

  addCupGroup(cupId: IDType, groupCreate: TournamentGroupCreate) {
    return TournamentService.addGroupToTournamentTournamentGroupTournamentIdPost(
      cupId,
      groupCreate
    );
  }

  deleteCupGroup(groupId: IDType, cupId: IDType) {
    return new CancelablePromise<TournamentGroupStanding[]>((resolve, reject) => {
      TournamentService.removeGroupFromTournamentTournamentGroupTournamentGroupIdDelete(
        groupId
      ).then(() => {
        TournamentService.getTournamentGroupStandingsTournamentGroupStandingsTournamentIdGet(
          cupId
        ).then(resolve, reject);
      }, reject);
    });
  }

  patchCupGroup(
    groupId: IDType,
    cupId: IDType,
    groupCreate: TournamentGroupPatch
  ) {
    return new CancelablePromise<TournamentGroup[]>((resolve, reject) => {
      TournamentService.patchGroupTournamentGroupTournamentGroupIdPatch(
        groupId,
        groupCreate
      ).then(() => {
        TournamentService.getTournamentGroupsTournamentGroupTournamentIdGet(
          cupId
        ).then(resolve, reject);
      }, reject);
    });
  }

  addCupStage(cupId: IDType, stageCreate: TournamentStageCreate) {
    return TournamentService.createTournamentStageTournamentStageTournamentIdPost(
      cupId,
      stageCreate
    );
  }

  deleteCupStage(stageId: IDType, cupId: IDType) {
    return new CancelablePromise<TournamentStage[]>((resolve, reject) => {
      TournamentService.removeStageFromTournamentTournamentStageTournamentStageIdDelete(
        stageId
      ).then(() => {
        TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
          cupId
        ).then(resolve, reject);
      }, reject);
    });
  }

  patchCupStage(
    stageId: IDType,
    cupId: IDType,
    stageCreate: TournamentStagePatch
  ) {
    return new CancelablePromise<TournamentStage[]>((resolve, reject) => {
      TournamentService.patchTournamentStageTournamentStageTournamentStageIdPatch(
        stageId,
        stageCreate
      ).then(() => {
        TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
          cupId
        ).then(resolve, reject);
      }, reject);
    });
  }

  createCupTeam(cupId: IDType, teamCreate: TournamentTeamCreate) {
    return TournamentService.addTeamToTournamentByNameTournamentTournamentIdTeamNamePost(
      cupId,
      teamCreate
    );
  }

  addCupTeams(cupId: IDType, teamIds: IDType[]) {
    return new CancelablePromise<Tournament>((resolve, reject) => {
      const promises: CancelablePromise<Tournament>[] = [];
      teamIds.forEach(teamId => {
        promises.push(TournamentService.addTeamToTournamentTournamentTournamentIdTeamTeamIdPost(
          teamId,
          cupId
        ));
      });
      Promise.allSettled(promises).then(() => {
        TournamentService.getTournamentTournamentTournamentIdGet(cupId).then(resolve, reject);
      });
    });
  }

  patchCupTeam(cupId: IDType, teamId: IDType, teamCreate: TeamPatch) {
    return new CancelablePromise<TournamentTeam>((resolve, reject) => {
      TeamService.patchTeamTeamTeamIdPatch(teamId, teamCreate).then(() => {
        TeamService.getTournamentTeamTeamTeamIdTournamentTournamentIdGet(
          teamId,
          cupId
        ).then((res) => {
          resolve(res);
        }, reject);
      }, reject);
    });
  }

  addTeamToCupGroup(teamId: IDType, group: IDType) {
    return TournamentService.addTeamToGroupByIdTournamentGroupTournamentGroupIdTeamTeamIdPost(
      group,
      teamId
    );
  }

  removeCupTeamGroup(teamId: IDType, stageId: IDType) {
    return TournamentService.removeTeamFromGroupTournamentGroupTournamentGroupIdTeamTeamIdDelete(
      stageId,
      teamId
    );
  }

  removeCupTeamCup(teamId: IDType, cupId: IDType) {
    return TournamentService.removeTeamFromTournamentTournamentTournamentIdTeamIdTeamIdDelete(
      teamId,
      cupId
    );
  }

  createCup(cup: TournamentCreate, location?: string) {
    return new CancelablePromise<Tournament>((resolve, reject) => {
      TournamentService.createTournamentTournamentPost(cup).then((newCup) => {
        if (location) {
          LocationService.createLocationLocationPost({
            name: { textDE: location, textEN: location, textIT: location },
            lat: 0,
            long: 0
          }).then((loc) => {
            TournamentService.patchTournamentTournamentTournamentIdPatch(
              newCup.id,
              {
                locationId: loc.id
              }
            ).then(resolve, reject);
          });
        } else {
          resolve(newCup);
        }
      }, reject);
    });
  }

  addCupMatchGroup(
    stageId: IDType,
    matchCreate: MatchTournamentGroupCreate,
    location?: string
  ) {
    return new CancelablePromise<MatchTournamentGroup>((resolve, reject) => {
      if (location) {
        LocationService.createLocationLocationPost({
          name: { textDE: location, textEN: location, textIT: location },
          lat: 0,
          long: 0
        }).then((loc) => {
          MatchService.createMatchTournamentGroupMatchesV2GroupTournamentGroupIdPost(
            stageId,
            {
              ...matchCreate,
              locationId: loc.id
            }
          ).then(resolve, reject);
        }, reject);
      } else {
        MatchService.createMatchTournamentGroupMatchesV2GroupTournamentGroupIdPost(
          stageId,
          matchCreate
        ).then(resolve, reject);
      }
    });
  }

  connectCupMatchStage(cupId: IDType, matchId: IDType, nextMatchId: IDType) {
    return new CancelablePromise<TournamentStage[]>((resolve, reject) => {
      MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
        matchId,
        {
          nextMatchId
        }
      ).then(() => {
        TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
          cupId
        ).then(resolve, reject);
      }, reject);
    });
  }

  addCupMatchStage(
    stageId: IDType,
    matchCreate: MatchTournamentStageCreate,
    location?: string,
    prevMatchId?: IDType
  ) {
    return new CancelablePromise<TournamentStage[]>((resolve, reject) => {
      if (location) {
        LocationService.createLocationLocationPost({
          name: { textDE: location, textEN: location, textIT: location },
          lat: 0,
          long: 0
        }).then((loc) => {
          MatchService.createMatchTournamentStageMatchesV2StageTournamentStageIdPost(
            stageId,
            {
              ...matchCreate,
              locationId: loc.id
            }
          ).then((match) => {
            if (prevMatchId) {
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                prevMatchId,
                {
                  nextMatchId: match.id
                }
              ).then(() => {
                TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
                  match.tournament.id
                ).then(resolve, reject);
              }, reject);
            } else {
              TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
                match.tournament.id
              ).then(resolve, reject);
            }
          }, reject);
        }, reject);
      } else {
        MatchService.createMatchTournamentStageMatchesV2StageTournamentStageIdPost(
          stageId,
          matchCreate
        ).then((match) => {
          if (prevMatchId) {
            MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
              prevMatchId,
              {
                nextMatchId: match.id
              }
            ).then(() => {
              TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
                match.tournament.id
              ).then(resolve, reject);
            }, reject);
          } else {
            TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
              match.tournament.id
            ).then(resolve, reject);
          }
        }, reject);
      }
    });
  }

  patchCupMatchGroup(
    matchId: IDType,
    matchPatch: MatchTournamentGroupPatch,
    location?: string
  ) {
    return new CancelablePromise<MatchTournamentGroup>((resolve, reject) => {
      if (location) {
        LocationService.createLocationLocationPost({
          name: { textDE: location, textEN: location, textIT: location },
          lat: 0,
          long: 0
        }).then((loc) => {
          MatchService.patchTournamentGroupMatchMatchesV2TournamentGroupMatchIdPatch(
            matchId,
            {
              ...matchPatch,
              locationId: loc.id
            }
          ).then(resolve, reject);
        }, reject);
      } else {
        MatchService.patchTournamentGroupMatchMatchesV2TournamentGroupMatchIdPatch(
          matchId,
          matchPatch
        ).then(resolve, reject);
      }
    });
  }

  createQuarters(
    startTime: number,
    matchSectionDurationMinutes: number,
    cupId: IDType,
    twoMatchesAtATime: boolean,
    pauseBetweenMatches: number,
    matchSectionPauseDurationMinutes: number,
    isLooser: boolean
  ) {
    const matchSectionAmount = matchSectionPauseDurationMinutes === 0 ? 1 : 2;
    const matchLengthMs =
      (matchSectionDurationMinutes * matchSectionAmount +
        matchSectionPauseDurationMinutes) *
      MINUTE_IN_MS;
    return new CancelablePromise<TournamentStage[]>((resolve, reject) => {
      TournamentService.createTournamentStageTournamentStageTournamentIdPost(
        cupId,
        {
          name: {
            textDE: "Viertelfinale",
            textIT: "Giro di 8",
            textEN: "Round of 8"
          },
          matchSectionAmount,
          matchSectionDurationMinutes,
          matchSectionPauseDurationMinutes,
          isLosersBracket: isLooser
        }
      ).then((quert) => {
        TournamentService.createTournamentStageTournamentStageTournamentIdPost(
          cupId,
          {
            name: {
              textDE: "Halbfinale",
              textIT: "Semifinali",
              textEN: "Semifinals"
            },
            matchSectionAmount,
            matchSectionDurationMinutes,
            matchSectionPauseDurationMinutes,
            isLosersBracket: isLooser
          }
        ).then((semi) => {
          TournamentService.createTournamentStageTournamentStageTournamentIdPost(
            cupId,
            {
              name: {
                textDE: "Finale",
                textIT: "Finali",
                textEN: "Finals"
              },
              matchSectionAmount,
              matchSectionDurationMinutes,
              matchSectionPauseDurationMinutes,
              isLosersBracket: isLooser
            }
          ).then((final) => {
            client()
              .createStageMatch(
                startTime +
                (twoMatchesAtATime
                  ? 3 * (matchLengthMs + pauseBetweenMatches)
                  : 6 * (matchLengthMs + pauseBetweenMatches)),
                matchLengthMs,
                "Gewinner S1",
                "Gewinner S2",
                final.id,
                null
              )
              .then((f) => {
                client()
                  .createStageMatch(
                    startTime +
                    (twoMatchesAtATime
                      ? 2 * (matchLengthMs + pauseBetweenMatches)
                      : 4 * (matchLengthMs + pauseBetweenMatches)),
                    matchLengthMs,
                    "Gewinner Q1",
                    "Gewinner Q2",
                    semi.id,
                    f.id
                  )
                  .then((s1) => {
                    client()
                      .createStageMatch(
                        startTime +
                        (twoMatchesAtATime
                          ? 2 * (matchLengthMs + pauseBetweenMatches)
                          : 5 * (matchLengthMs + pauseBetweenMatches)),
                        matchLengthMs,
                        "Gewinner Q3",
                        "Gewinner Q4",
                        semi.id,
                        f.id
                      )
                      .then((s2) => {
                        client()
                          .createStageMatch(
                            startTime,
                            matchLengthMs,
                            "Gewinner A",
                            "Zweiter B",
                            quert.id,
                            s1.id
                          )
                          .then(() => {
                            client()
                              .createStageMatch(
                                startTime +
                                (twoMatchesAtATime
                                  ? 0
                                  : matchLengthMs + pauseBetweenMatches),
                                matchLengthMs,
                                "Gewinner B",
                                "Zweiter A",
                                quert.id,
                                s1.id
                              )
                              .then(() => {
                                client()
                                  .createStageMatch(
                                    startTime +
                                    (twoMatchesAtATime
                                      ? matchLengthMs + pauseBetweenMatches
                                      : 2 *
                                      (matchLengthMs +
                                        pauseBetweenMatches)),
                                    matchLengthMs,
                                    "Gewinner C",
                                    "Zweiter D",
                                    quert.id,
                                    s2.id
                                  )
                                  .then(() => {
                                    client()
                                      .createStageMatch(
                                        startTime +
                                        (twoMatchesAtATime
                                          ? matchLengthMs +
                                          pauseBetweenMatches
                                          : 3 *
                                          (matchLengthMs +
                                            pauseBetweenMatches)),
                                        matchLengthMs,
                                        "Gewinner D",
                                        "Zweiter C",
                                        quert.id,
                                        s2.id
                                      )
                                      .then(() => {
                                        TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
                                          cupId
                                        ).then(resolve, reject);
                                      }, reject);
                                  }, reject);
                              }, reject);
                          }, reject);
                      }, reject);
                  }, reject);
              }, reject);
          }, reject);
        }, reject);
      }, reject);
    });
  }

  createEighters(
    startTime: number,
    matchSectionDurationMinutes: number,
    cupId: IDType,
    twoMatchesAtATime: boolean,
    pauseBetweenMatches: number,
    matchSectionPauseDurationMinutes: number,
    isLooser: boolean
  ) {
    const matchSectionAmount = matchSectionPauseDurationMinutes === 0 ? 1 : 2;
    const matchLengthMs =
      (matchSectionDurationMinutes * matchSectionAmount +
        matchSectionPauseDurationMinutes) *
      MINUTE_IN_MS;
    return new CancelablePromise<TournamentStage[]>((resolve, reject) => {
      TournamentService.createTournamentStageTournamentStageTournamentIdPost(
        cupId,
        {
          name: {
            textDE: "Achtelfinale",
            textIT: "Giro di 16",
            textEN: "Round of 16"
          },
          matchSectionAmount,
          matchSectionDurationMinutes,
          matchSectionPauseDurationMinutes,
          isLosersBracket: isLooser
        }
      ).then((eight) => {
        client()
          .createQuarters(
            startTime +
            (twoMatchesAtATime
              ? 4 * (matchLengthMs + pauseBetweenMatches)
              : 8 * (matchLengthMs + pauseBetweenMatches)),
            matchSectionDurationMinutes,
            cupId,
            twoMatchesAtATime,
            pauseBetweenMatches,
            matchSectionPauseDurationMinutes,
            isLooser
          )
          .then((stages) => {
            const quart = stages.find((s) => s.matches.length === 4);
            if (quart) {
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                quart.matches[0].id,
                {
                  team1Placeholder: "Gewinner A1",
                  team2Placeholder: "Gewinner A2"
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                quart.matches[1].id,
                {
                  team1Placeholder: "Gewinner A3",
                  team2Placeholder: "Gewinner A4"
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                quart.matches[2].id,
                {
                  team1Placeholder: "Gewinner A5",
                  team2Placeholder: "Gewinner A6"
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                quart.matches[3].id,
                {
                  team1Placeholder: "Gewinner A7",
                  team2Placeholder: "Gewinner A8"
                }
              );
              client()
                .createStageMatch(
                  startTime,
                  matchLengthMs,
                  "Gewinner A",
                  "Zweiter B",
                  eight.id,
                  quart.matches[0].id
                )
                .then(() => {
                  client()
                    .createStageMatch(
                      startTime +
                      (twoMatchesAtATime
                        ? 0
                        : matchLengthMs + pauseBetweenMatches),
                      matchLengthMs,
                      "Gewinner B",
                      "Zweiter A",
                      eight.id,
                      quart.matches[0].id
                    )
                    .then(() => {
                      client()
                        .createStageMatch(
                          startTime +
                          (twoMatchesAtATime
                            ? matchLengthMs + pauseBetweenMatches
                            : 2 * (matchLengthMs + pauseBetweenMatches)),
                          matchLengthMs,
                          "Gewinner C",
                          "Zweiter D",
                          eight.id,
                          quart.matches[1].id
                        )
                        .then(() => {
                          client()
                            .createStageMatch(
                              startTime +
                              (twoMatchesAtATime
                                ? matchLengthMs + pauseBetweenMatches
                                : 3 * (matchLengthMs + pauseBetweenMatches)),
                              matchLengthMs,
                              "Gewinner D",
                              "Zweiter C",
                              eight.id,
                              quart.matches[1].id
                            )
                            .then(() => {
                              client()
                                .createStageMatch(
                                  startTime +
                                  (twoMatchesAtATime
                                    ? 2 *
                                    (matchLengthMs + pauseBetweenMatches)
                                    : 4 *
                                    (matchLengthMs + pauseBetweenMatches)),
                                  matchLengthMs,
                                  "Gewinner E",
                                  "Zweiter F",
                                  eight.id,
                                  quart.matches[2].id
                                )
                                .then(() => {
                                  client()
                                    .createStageMatch(
                                      startTime +
                                      (twoMatchesAtATime
                                        ? 2 *
                                        (matchLengthMs +
                                          pauseBetweenMatches)
                                        : 5 *
                                        (matchLengthMs +
                                          pauseBetweenMatches)),
                                      matchLengthMs,
                                      "Gewinner F",
                                      "Zweiter E",
                                      eight.id,
                                      quart.matches[2].id
                                    )
                                    .then(() => {
                                      client()
                                        .createStageMatch(
                                          startTime +
                                          (twoMatchesAtATime
                                            ? 3 *
                                            (matchLengthMs +
                                              pauseBetweenMatches)
                                            : 6 *
                                            (matchLengthMs +
                                              pauseBetweenMatches)),
                                          matchLengthMs,
                                          "Gewinner G",
                                          "Zweiter H",
                                          eight.id,
                                          quart.matches[3].id
                                        )
                                        .then(() => {
                                          client()
                                            .createStageMatch(
                                              startTime +
                                              (twoMatchesAtATime
                                                ? 3 *
                                                (matchLengthMs +
                                                  pauseBetweenMatches)
                                                : 7 *
                                                (matchLengthMs +
                                                  pauseBetweenMatches)),
                                              matchLengthMs,
                                              "Gewinner H",
                                              "Zweiter G",
                                              eight.id,
                                              quart.matches[3].id
                                            )
                                            .then(() => {
                                              TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
                                                cupId
                                              ).then(resolve, reject);
                                            }, reject);
                                        }, reject);
                                    }, reject);
                                }, reject);
                            }, reject);
                        }, reject);
                    }, reject);
                }, reject);
            } else {
              reject();
            }
          }, reject);
      });
    });
  }

  createSixteenth(
    startTime: number,
    matchSectionDurationMinutes: number,
    cupId: IDType,
    twoMatchesAtATime: boolean,
    pauseBetweenMatches: number,
    matchSectionPauseDurationMinutes: number,
    isLooser: boolean
  ) {
    const matchSectionAmount = matchSectionPauseDurationMinutes === 0 ? 1 : 2;
    const matchLengthMs =
      (matchSectionDurationMinutes * matchSectionAmount +
        matchSectionPauseDurationMinutes) *
      MINUTE_IN_MS;
    return new CancelablePromise<TournamentStage[]>((resolve, reject) => {
      TournamentService.createTournamentStageTournamentStageTournamentIdPost(
        cupId,
        {
          name: {
            textDE: "Sechzehntelfinale",
            textIT: "Giro di 32",
            textEN: "Round of 32"
          },
          matchSectionAmount,
          matchSectionDurationMinutes,
          matchSectionPauseDurationMinutes,
          isLosersBracket: isLooser
        }
      ).then((sixteenth) => {
        client()
          .createEighters(
            startTime,
            matchSectionDurationMinutes,
            cupId,
            twoMatchesAtATime,
            pauseBetweenMatches,
            matchSectionPauseDurationMinutes,
            isLooser
          )
          .then((stages) => {
            const eight = stages.find((s) => s.matches.length === 8);
            if (eight) {
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                eight.matches[0].id,
                {
                  team1Placeholder: "Gewinner 16'1",
                  team2Placeholder: "Gewinner 16'2"
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                eight.matches[1].id,
                {
                  team1Placeholder: "Gewinner 16'3",
                  team2Placeholder: "Gewinner 16'4"
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                eight.matches[2].id,
                {
                  team1Placeholder: "Gewinner 16'5",
                  team2Placeholder: "Gewinner 16'6"
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                eight.matches[3].id,
                {
                  team1Placeholder: "Gewinner 16'7",
                  team2Placeholder: "Gewinner 16'8"
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                eight.matches[4].id,
                {
                  team1Placeholder: "Gewinner 16'9",
                  team2Placeholder: "Gewinner 16'10"
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                eight.matches[5].id,
                {
                  team1Placeholder: "Gewinner 16'11",
                  team2Placeholder: "Gewinner 16'12"
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                eight.matches[6].id,
                {
                  team1Placeholder: "Gewinner 16'13",
                  team2Placeholder: "Gewinner 16'14"
                }
              );
              MatchService.patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
                eight.matches[7].id,
                {
                  team1Placeholder: "Gewinner 16'15",
                  team2Placeholder: "Gewinner 16'16"
                }
              );
              client()
                .createStageMatch(
                  startTime,
                  matchLengthMs,
                  "Gewinner A",
                  "Zweiter B",
                  sixteenth.id,
                  eight.matches[0].id
                )
                .then(() => {
                  client()
                    .createStageMatch(
                      startTime +
                      (twoMatchesAtATime
                        ? 0
                        : matchLengthMs + pauseBetweenMatches),
                      matchLengthMs,
                      "Gewinner B",
                      "Zweiter A",
                      sixteenth.id,
                      eight.matches[0].id
                    )
                    .then(() => {
                      client()
                        .createStageMatch(
                          startTime +
                          (twoMatchesAtATime
                            ? matchLengthMs + pauseBetweenMatches
                            : 2 * (matchLengthMs + pauseBetweenMatches)),
                          matchLengthMs,
                          "Gewinner C",
                          "Zweiter D",
                          sixteenth.id,
                          eight.matches[1].id
                        )
                        .then(() => {
                          client()
                            .createStageMatch(
                              startTime +
                              (twoMatchesAtATime
                                ? matchLengthMs + pauseBetweenMatches
                                : 3 * (matchLengthMs + pauseBetweenMatches)),
                              matchLengthMs,
                              "Gewinner D",
                              "Zweiter C",
                              sixteenth.id,
                              eight.matches[1].id
                            )
                            .then(() => {
                              client()
                                .createStageMatch(
                                  startTime +
                                  (twoMatchesAtATime
                                    ? 2 *
                                    (matchLengthMs + pauseBetweenMatches)
                                    : 4 *
                                    (matchLengthMs + pauseBetweenMatches)),
                                  matchLengthMs,
                                  "Gewinner E",
                                  "Zweiter F",
                                  sixteenth.id,
                                  eight.matches[2].id
                                )
                                .then(() => {
                                  client()
                                    .createStageMatch(
                                      startTime +
                                      (twoMatchesAtATime
                                        ? 2 *
                                        (matchLengthMs +
                                          pauseBetweenMatches)
                                        : 5 *
                                        (matchLengthMs +
                                          pauseBetweenMatches)),
                                      matchLengthMs,
                                      "Gewinner F",
                                      "Zweiter E",
                                      sixteenth.id,
                                      eight.matches[2].id
                                    )
                                    .then(() => {
                                      client()
                                        .createStageMatch(
                                          startTime +
                                          (twoMatchesAtATime
                                            ? 3 *
                                            (matchLengthMs +
                                              pauseBetweenMatches)
                                            : 6 *
                                            (matchLengthMs +
                                              pauseBetweenMatches)),
                                          matchLengthMs,
                                          "Gewinner G",
                                          "Zweiter H",
                                          sixteenth.id,
                                          eight.matches[3].id
                                        )
                                        .then(() => {
                                          client()
                                            .createStageMatch(
                                              startTime +
                                              (twoMatchesAtATime
                                                ? 3 *
                                                (matchLengthMs +
                                                  pauseBetweenMatches)
                                                : 7 *
                                                (matchLengthMs +
                                                  pauseBetweenMatches)),
                                              matchLengthMs,
                                              "Gewinner H",
                                              "Zweiter G",
                                              sixteenth.id,
                                              eight.matches[3].id
                                            )
                                            .then(() => {
                                              client()
                                                .createStageMatch(
                                                  startTime,
                                                  matchLengthMs,
                                                  "Gewinner I",
                                                  "Zweiter J",
                                                  sixteenth.id,
                                                  eight.matches[4].id
                                                )
                                                .then(() => {
                                                  client()
                                                    .createStageMatch(
                                                      startTime +
                                                      (twoMatchesAtATime
                                                        ? 0
                                                        : matchLengthMs +
                                                        pauseBetweenMatches),
                                                      matchLengthMs,
                                                      "Gewinner J",
                                                      "Zweiter I",
                                                      sixteenth.id,
                                                      eight.matches[4].id
                                                    )
                                                    .then(() => {
                                                      client()
                                                        .createStageMatch(
                                                          startTime +
                                                          (twoMatchesAtATime
                                                            ? matchLengthMs +
                                                            pauseBetweenMatches
                                                            : 2 *
                                                            (matchLengthMs +
                                                              pauseBetweenMatches)),
                                                          matchLengthMs,
                                                          "Gewinner K",
                                                          "Zweiter L",
                                                          sixteenth.id,
                                                          eight.matches[5].id
                                                        )
                                                        .then(() => {
                                                          client()
                                                            .createStageMatch(
                                                              startTime +
                                                              (twoMatchesAtATime
                                                                ? matchLengthMs +
                                                                pauseBetweenMatches
                                                                : 3 *
                                                                (matchLengthMs +
                                                                  pauseBetweenMatches)),
                                                              matchLengthMs,
                                                              "Gewinner L",
                                                              "Zweiter K",
                                                              sixteenth.id,
                                                              eight.matches[5]
                                                                .id
                                                            )
                                                            .then(() => {
                                                              client()
                                                                .createStageMatch(
                                                                  startTime +
                                                                  (twoMatchesAtATime
                                                                    ? 2 *
                                                                    (matchLengthMs +
                                                                      pauseBetweenMatches)
                                                                    : 4 *
                                                                    (matchLengthMs +
                                                                      pauseBetweenMatches)),
                                                                  matchLengthMs,
                                                                  "Gewinner M",
                                                                  "Zweiter N",
                                                                  sixteenth.id,
                                                                  eight
                                                                    .matches[6]
                                                                    .id
                                                                )
                                                                .then(() => {
                                                                  client()
                                                                    .createStageMatch(
                                                                      startTime +
                                                                      (twoMatchesAtATime
                                                                        ? 2 *
                                                                        (matchLengthMs +
                                                                          pauseBetweenMatches)
                                                                        : 5 *
                                                                        (matchLengthMs +
                                                                          pauseBetweenMatches)),
                                                                      matchLengthMs,
                                                                      "Gewinner N",
                                                                      "Zweiter M",
                                                                      sixteenth.id,
                                                                      eight
                                                                        .matches[6]
                                                                        .id
                                                                    )
                                                                    .then(
                                                                      () => {
                                                                        client()
                                                                          .createStageMatch(
                                                                            startTime +
                                                                            (twoMatchesAtATime
                                                                              ? 3 *
                                                                              (matchLengthMs +
                                                                                pauseBetweenMatches)
                                                                              : 6 *
                                                                              (matchLengthMs +
                                                                                pauseBetweenMatches)),
                                                                            matchLengthMs,
                                                                            "Gewinner O",
                                                                            "Zweiter P",
                                                                            sixteenth.id,
                                                                            eight
                                                                              .matches[7]
                                                                              .id
                                                                          )
                                                                          .then(
                                                                            () => {
                                                                              client()
                                                                                .createStageMatch(
                                                                                  startTime +
                                                                                  (twoMatchesAtATime
                                                                                    ? 3 *
                                                                                    (matchLengthMs +
                                                                                      pauseBetweenMatches)
                                                                                    : 7 *
                                                                                    (matchLengthMs +
                                                                                      pauseBetweenMatches)),
                                                                                  matchLengthMs,
                                                                                  "Gewinner P",
                                                                                  "Zweiter O",
                                                                                  sixteenth.id,
                                                                                  eight
                                                                                    .matches[7]
                                                                                    .id
                                                                                )
                                                                                .then(
                                                                                  () => {
                                                                                    TournamentService.getTournamentStagesTournamentStageTournamentIdGet(
                                                                                      cupId
                                                                                    ).then(
                                                                                      resolve,
                                                                                      reject
                                                                                    );
                                                                                  },
                                                                                  reject
                                                                                );
                                                                            },
                                                                            reject
                                                                          );
                                                                      },
                                                                      reject
                                                                    );
                                                                }, reject);
                                                            }, reject);
                                                        }, reject);
                                                    }, reject);
                                                }, reject);
                                            }, reject);
                                        }, reject);
                                    }, reject);
                                }, reject);
                            }, reject);
                        }, reject);
                    }, reject);
                }, reject);
            } else {
              reject();
            }
          }, reject);
      }, reject);
    });
  }

  private filterAoi(
    aois: AreaOfInterest[],
    id: IDType,
    resolve: (value: PromiseLike<unknown> | unknown) => void,
    reject: (reason?: ApiError) => void
  ): void {
    const aoisFilter = aois.filter((aoi) => aoi.id === id);
    if (aoisFilter.length === 1) {
      resolve(aoisFilter[0]);
    } else {
      reject(
        new ApiError(
          { url: "aoi", method: "GET" },
          {
            body: "asd",
            ok: false,
            status: 420,
            statusText: "invalid_id",
            url: "locale"
          },
          "invalid_id"
        )
      );
    }
  }

  private createStageMatch(
    startTime: number,
    matchLengthMs: number,
    a: string,
    b: string,
    stageId: IDType,
    nextMatch: IDType | null
  ) {
    return MatchService.createMatchTournamentStageMatchesV2StageTournamentStageIdPost(
      stageId,
      {
        team1Placeholder: a,
        team2Placeholder: b,
        originalStartTime: formatDate(
          new Date(startTime),
          LanguageLocaleEnum.DE,
          true
        ),
        externalId: null,
        refereeId: null,
        team1Id: null,
        team2Id: null,
        locationId: null,
        nextMatchId: nextMatch
      }
    );
  }
}

const client = Client.getInstance;
export default client;
export { Client as StaticClient };

// rel oad
