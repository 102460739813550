import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { NotificationCreate } from "./types";
import { ScopeEnum, Task } from "../../client/api";
import { IDType } from "../../config/types";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import { showTranslated, translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import NotificationIcon from "../../components/Icons/NotificationIcon";
import ClavaButton from "../../components/Atoms/ClavaButton";
import logoSymbol from "../../assets/images/logo-symbol.png";

const NotificationsReview: React.FC<{
  notification: NotificationCreate;
  scopes: ScopeEnum[];
  insiderOfTeam: IDType[];
  aois: IDType[];
  langs: string[];
  registered: undefined | boolean;
  isInsider: undefined | boolean;
  isPlayer: undefined | boolean;
  query: string;
  selected: IDType[];
  setTask: React.Dispatch<React.SetStateAction<Task | undefined>>;
  setFailed: React.Dispatch<React.SetStateAction<number[] | undefined>>;
}> = ({
        notification,
        query,
        insiderOfTeam,
        isInsider, setFailed,
        setTask,
        isPlayer,
        langs,
        registered,
        scopes,
        selected,
        aois
      }) => {
  const { loading, call } = useServer(true);
  const { l } = useContext(ClavaContext);
  const [amount, setAmount] = useState<number>(selected.length);
  useEffect(() => {
    if (selected.length === 0) {
      call("countUser", [query === "" ? " " : query,
        scopes.length === 0 ? undefined : scopes,
        aois.length === 0 ? undefined : aois,
        insiderOfTeam.length === 0 ? undefined : insiderOfTeam,
        isInsider,
        isPlayer,
        langs.length === 0 ? undefined : langs,
        typeof registered === "undefined" ? undefined : !registered]).then(setAmount);
    } else {
      setAmount(selected.length);
    }
  }, [aois, call, insiderOfTeam, isInsider, isPlayer, langs, query, registered, scopes, selected.length]);
  const beautifulAmount = useMemo(() => {
    if (amount === 0) {
      return translate("none", l);
    }
    if (selected.length !== 0) {
      return amount.toString(10);
    }
    return `~${amount}`;
  }, [selected, amount, l]);
  const onSend = useCallback(() => {
    if (selected.length === 0) {
      call("sendNotificationMany", [notification, query === "" ? " " : query,
        scopes.length === 0 ? undefined : scopes,
        aois.length === 0 ? undefined : aois,
        insiderOfTeam.length === 0 ? undefined : insiderOfTeam,
        isInsider,
        isPlayer,
        langs.length === 0 ? undefined : langs,
        typeof registered === "undefined" ? undefined : !registered]).then(setTask);
    } else {
      call("sendNotification", [{
        ...notification,
        user_ids: selected
      }]).then(setFailed);
    }
  }, [aois, call, insiderOfTeam, isInsider, isPlayer, langs, notification, query, registered, scopes, selected, setFailed, setTask]);
  return (
    <>
      <ListBlockHeader title={translate("notificationsSentTo", l, { "[amount]": beautifulAmount })}
                       icon={<NotificationIcon />} />
      <LoadingBlock isLoading={loading} className="flex flex-col items-start justify-start">
        <div className="my-4">
          <h2 className="font-bold block text-xl">Preview</h2>
          <div
            className="flex flex-col min-w-64 self-center my-4 p-2 mx-auto items-start justify-start bg-light-gray dark:bg-light-gray-dark rounded-xl">
            <span className="font-bold">{showTranslated(notification.title, l)}</span>
            <div className="flex self-stretch py-2 pr-2 flex-row items-start justify-between">
              <span>{showTranslated(notification.body, l)}</span>
              <img className={"w-10"}
                   src={logoSymbol}
                   alt={"Logo"} />
            </div>
          </div>
        </div>
        <ClavaButton onClick={onSend}>
          Ausn mit der
        </ClavaButton>
      </LoadingBlock>
    </>
  );
};

export default NotificationsReview;
