import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { ClavaContext } from "../../config/contexts";
import {
    CancelablePromise,
    LanguageLocaleEnum,
    ScopeEnum,
    type UserEssential
} from "../../client/api";
import { translate } from "../../config/translator";
import { TableRowItem, useList } from "../../hooks/useList";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";
import useServer from "../../hooks/useServer";
import { ClavaSelectItems, IDType } from "../../config/types";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import NotificationIcon from "../../components/Icons/NotificationIcon";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import ClavaSwitchTri from "../../components/Atoms/ClavaSwitchTri";
import { useAois } from "../../hooks/useAois";
import ClavaSelectMultiple from "../../components/Atoms/ClavaSelectMultiple";

const items: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "name"
  },
  {
    label: "username"
  },
  {
    label: "email"
  },
  {
    label: "tel"
  },
  {
    label: "status"
  }
];
const NotificationsUsers: React.FC<{
  scopes: ScopeEnum[];
  setScopes: React.Dispatch<React.SetStateAction<ScopeEnum[]>>;
  insiderOfTeam: IDType[];
  setInsiderOfTeam: React.Dispatch<React.SetStateAction<IDType[]>>;
  aois: IDType[];
  setAois: React.Dispatch<React.SetStateAction<IDType[]>>;
  langs: string[];
  setLangs: React.Dispatch<React.SetStateAction<string[]>>;
  registered: undefined | boolean;
  setRegistered: React.Dispatch<React.SetStateAction<undefined | boolean>>;
  isInsider: undefined | boolean;
  setIsInsider: React.Dispatch<React.SetStateAction<undefined | boolean>>;
  isPlayer: undefined | boolean;
  setIsPlayer: React.Dispatch<React.SetStateAction<undefined | boolean>>;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  setSelected: React.Dispatch<React.SetStateAction<IDType[]>>;
  active: boolean;
}> = ({
        aois,
        langs,
        scopes,
        insiderOfTeam,
        isInsider,
        isPlayer,
        setQuery,
        registered,
        setIsInsider,
        setRegistered,
        setAois,
        setIsPlayer,
        setInsiderOfTeam,
        setLangs,
        setScopes,
        active,
        setSelected
      }) => {
  const { l } = useContext(ClavaContext);
  const mapper = useCallback((us: UserEssential) => {
    return {
      id: us.id,
      search: `${us.username} ${us.givenName} ${us.familyName} ${us.email}`.toLowerCase(),
      items: [
        { label: us.id },
        { label: `${us.givenName} ${us.familyName}` },
        { label: us.username },
        {
          label: us.email,
          pill: {
            type: us.emailConfirmed ? "success" : "danger",
            text: us.emailConfirmed ? "confirmed" : "notConfirmed"
          }
        },
        {
          label: us.tel,
          pill: {

            type: us.telConfirmed ? "success" : "danger",
            text: us.telConfirmed ? "confirmed" : "notConfirmed"
          }
        },
        {
          centered: true,
          pill: !!us.playerId ? { type: "success", text: "connected" } : {
            type: "danger",
            text: "notConnected"
          }
        }
      ]
    } as TableRowItem;
  }, []);
  const {
    aoiItems,
    selectedAois,
    onChangeAois
  } = useAois(true, aois, (val) => {
    setAois(val);
  });
  const {
    q,
    setQ,
    onCheckAll,
    selected,
    allSelected,
    filtered,
    setItems,
    onCheck, limit
  } = useList<UserEssential>(mapper, 30);
  useEffect(() => {
    setSelected(selected);
  }, [setSelected, selected]);
  useEffect(() => {
    setQuery(q);
  }, [setQuery, q]);
  const { loading, call } = useServer(true);
  const onSearch = useCallback((off: number, lim: number) => {
    if (active) {
      return call("searchUser", [
        q === "" ? " " : q,
        off,
        lim,
        scopes.length === 0 ? undefined : scopes,
        aois.length === 0 ? undefined : aois,
        insiderOfTeam.length === 0 ? undefined : insiderOfTeam,
        isInsider,
        isPlayer,
        langs.length === 0 ? undefined : langs,
        typeof registered === "undefined" ? undefined : !registered]);
    } else {
      return new CancelablePromise<UserEssential[]>((r) => {
        r([]);
      });
    }
  }, [active, call, q, scopes, aois, insiderOfTeam, isInsider, langs, isPlayer, registered]);
  useInfiniteScroll(onSearch, setItems, 0, limit);

  const langItems = useMemo<ClavaSelectItems[]>(() => {
    return [
      {
        label: translate("german", l),
        key: LanguageLocaleEnum.DE
      },
      {
        label: translate("italian", l),
        key: LanguageLocaleEnum.IT
      },
      {
        label: translate("english", l),
        key: LanguageLocaleEnum.EN
      }
    ];
  }, [l]);
  return (
    <React.Fragment>
      <ListBlockHeader title={"notificationUser"} searchTitle onSearch={active ? setQ : undefined}
                       q={q}
                       icon={<NotificationIcon />}>
        {active && (
          <div className="flex flex-row">
            <ClavaSelectMultiple uniqueId={"longs-not"} items={langItems} onChange={setLangs}
                                 label={translate("language", l)} value={langs}
                                 className="flex-1 mx-2" />
            <ClavaSelectMultiple uniqueId={"aois-not"} items={aoiItems} onChange={onChangeAois}
                                 label={translate("province", l)} value={selectedAois}
                                 className="flex-1 mx-2" />
            <ClavaSwitchTri className="mr-4" value={registered} onChange={setRegistered}
                            label={translate("registered", l)} />
            <ClavaSwitchTri className="mr-4" value={isInsider} onChange={setIsInsider}
                            label={translate("insider", l)} />
            <ClavaSwitchTri className="mr-4" value={isPlayer} onChange={setIsPlayer}
                            label={translate("player", l)} />
          </div>
        )}
      </ListBlockHeader>
      {active && (
        <LoadingBlock isLoading={loading} smallLoading={filtered.length !== 0}>
          <Table>
            <TableHeader items={items} onCheckAll={onCheckAll} allChecked={allSelected} />
            <tbody>
            {filtered.map(fil => (
              <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                        checked={selected.includes(fil.id)}
                        key={"notifications-" + fil.id} />
            ))}
            </tbody>
          </Table>
        </LoadingBlock>
      )}
    </React.Fragment>
  );
};

export default NotificationsUsers;
