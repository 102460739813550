import { Action, Dispatch } from "redux";
import client from "../../client";
import { defaultGet } from "./all";
import { GeneralActionTypes } from "./types";
import { AS_TASK_STORAGE } from "../../config/constants";
import { TaskExt } from "../../config/types";

export function fetchPlayerPositions(
  dispatch: Dispatch<Action<GeneralActionTypes>>
) {
  defaultGet(
    dispatch,
    GeneralActionTypes.FETCH_PLAYER_POSITIONS_SUCCESS,
    GeneralActionTypes.FETCH_PLAYER_POSITIONS_ERROR,
    GeneralActionTypes.FETCH_PLAYER_POSITIONS,
    client().getPLayerPositions,
    false,
    false
  );
}

function getTaskStorage(): TaskExt[] {
  const tasksStr = localStorage.getItem(AS_TASK_STORAGE);
  if (tasksStr) {
    return JSON.parse(tasksStr) as TaskExt[];
  }
  return [];
}

function saveTaskStorage(tasks: TaskExt[]): void {
  localStorage.setItem(AS_TASK_STORAGE, JSON.stringify(tasks));
}

export function saveTask(dispatch: Dispatch<Action<GeneralActionTypes>>, task: TaskExt) {
  const tasks = getTaskStorage();
  saveTaskStorage(tasks.filter(t => t.id !== task.id).concat(task));
  dispatch({ type: GeneralActionTypes.ADD_TASK, payload: task });
}

export function removeTask(dispatch: Dispatch<Action<GeneralActionTypes>>, id: string) {
  const tasks = getTaskStorage();
  saveTaskStorage(tasks.filter(t => t.id !== id));
  dispatch({ type: GeneralActionTypes.REMOVE_TASK, payload: id });
}

export function refreshTask(dispatch: Dispatch<Action<GeneralActionTypes>>, id: string) {
  defaultGet(dispatch,
    GeneralActionTypes.FETCH_TASK_SUCCESS,
    GeneralActionTypes.FETCH_TASK_ERROR,
    GeneralActionTypes.FETCH_TASK,
    client().getTask,
    false,
    false,
    id);
}

export function loadTasks(dispatch: Dispatch<Action<GeneralActionTypes>>) {
  const tasks = getTaskStorage();
  if (tasks.length > 0) {
    dispatch({ type: GeneralActionTypes.LOAD_TASKS, payload: tasks });
  }
}
