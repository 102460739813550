import React, { useCallback, useContext, useMemo, useState } from "react";
import TableHeader from "../../components/Table/TableHeader";
import { ClavaContext } from "../../config/contexts";
import { MatchLeague, MatchListElement } from "../../client/api";
import { useNavigate } from "react-router";
import useServer from "../../hooks/useServer";
import { TableRowItem, useList } from "../../hooks/useList";
import { showTranslated, translate } from "../../config/translator";
import { faPencil, faTrash } from "@fortawesome/pro-light-svg-icons";
import {
  formatDate,
  getMatchDate,
  mapMatchLeagueToMatchList,
  matchLeagueToMatchListElement
} from "../../config/utils";
import { IDType } from "../../config/types";
import Filter, { FilterProp } from "../../components/Views/Filter";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faPlus } from "@fortawesome/pro-solid-svg-icons";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import useBoolState from "../../hooks/useBoolState";
import MatchIcon from "../../components/Icons/MatchIcon";
import Table from "../../components/Table/Table";
import TableRow from "../../components/Table/TableRow";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import MessageBox from "../../components/Atoms/MessageBox";
import FileUploader from "../../components/FileUploader/FileUploader";
import CsvView from "../../components/Import/CsvView";
import MatchCreateModal from "./MatchCreateModal";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import BulkActions from "../../components/Table/BulkActions";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import { saveTask } from "../../redux/actions/generalActions";
import { useDispatch } from "react-redux";

const headers: TableHeaderItem[] = [
  {
    label: "id",
    sortable: true,
    key: "id"
  },
  {
    label: "matchDay",
    sortable: true,
    key: "matchDay"
  },
  {
    label: "date"
  },
  {
    label: "time"
  },
  {
    label: "league"
  },
  {
    label: "team1"
  },
  {
    label: "team2"
  },
  {
    label: "goal1"
  },
  {
    label: "goal2"
  },
  {
    label: "lineup1"
  },
  {
    label: "lineup2"
  },
  {
    label: "action"
  }
];
const bulkInitial = {
  startTime: "",
  matchDay: 0
};
const bulkProps: (keyof typeof bulkInitial)[] = [
  "startTime",
  "matchDay"
];
const numberProps: (keyof typeof bulkInitial)[] = [
  "matchDay"
];
const dateProps: (keyof typeof bulkInitial)[] = [
  "startTime"
];
const MatchList = () => {
  const { l, filterAoi, sports } = useContext(ClavaContext);
  const [del, setDel] = useState<MatchListElement>();
  const [create, toggleCreate] = useBoolState(false);
  const [importCsv, toggleImportCsv] = useBoolState(false);
  const [file, setFile] = useState<File>();
  const { call, loading, status, StatusComponent } = useServer(true);
  const navigator = useNavigate();
  const mapper = useCallback((item: MatchListElement): TableRowItem => {
    return {
      search: `${showTranslated(item.team1.name, l)} ${showTranslated(item.team2.name, l)}`.toLowerCase(),
      id: item.id,
      items: [
        {
          label: item.id,
          key: "id"
        }, {
          label: item.matchDay,
          key: "matchDay"
        }, {
          label: formatDate(getMatchDate(item), l, false, false, true)
        }, {
          label: formatDate(getMatchDate(item), l, false, true)
        }, {
          label: `${showTranslated(item.league.name, l)} ${item.league.year}  {Id:league${item.league.id}}`
        }, {
          label: `${showTranslated(item.team1.name, l)} {Id:team${item.team1.id}}`
        }, {
          label: `${showTranslated(item.team2.name, l)} {Id:team${item.team2.id}}`
        }, {
          label: item.goal1
        }, {
          label: item.goal2
        }, {
          pill: {
            type: !!item.lineupTeam1Id ? "success" : "danger",
            text: !!item.lineupTeam1Id ? "yes" : "no"
          }
        }, {
          pill: {
            type: !!item.lineupTeam2Id ? "success" : "danger",
            text: !!item.lineupTeam2Id ? "yes" : "no"
          }
        }, {
          buttons: [{
            icon: faPencil,
            onClick: () => {
              navigator("/match/" + item.id);
            },
            type: "primary"
          },
            {
              icon: faTrash,
              onClick: () => {
                setDel(item);
              },
              type: "danger"
            }]
        }]
    };
  }, [l, navigator]);
  const {
    q,
    setQ,
    setItems,
    filtered,
    selected,
    allSelected,
    onCheck,
    onCheckAll,
    sthChanges,
    limit, sorted, onSort
  } = useList(mapper, 30);
  const toggleDelete = useCallback(() => {
    setDel(undefined);
  }, []);
  const [leagueId, setLeagueId] = useState<IDType>();
  const [aoiId, setAoiId] = useState<string | undefined>(filterAoi);
  const [teamId, setTeamId] = useState<IDType>();
  const [wrongInput, setWrongInput] = useState(false);
  const [deleteMultiple, toggleDeleteMultiple] = useBoolState(false);
  const [confirmDeleteMultiple, setConfirmDeleteMultiple] = useState("");
  const [matchDay, setMatchDay] = useState<string>();
  const [matchDays, setMatchDays] = useState<number[]>();
  const setMatchDaysCont = useCallback((val: string[]) => {
    setMatchDays(val.map(v => parseInt(v, 10)));
  }, []);
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const onSearch = useCallback((off: number, lim: number) => {
    const numM = matchDay && matchDay !== "" ? parseInt(matchDay, 10) : Number.NaN;
    return call("searchMatches", [
      q.length === 0 ? " " : q,
      off,
      lim,
      aoiId,
      leagueId,
      teamId,
      Number.isNaN(numM) ? undefined : numM,
      dateFrom ? formatDate(dateFrom, l, true, false, true) : undefined,
      dateTo ? formatDate(dateTo, l, true, false, true) : undefined,
      matchDays]);
  }, [dateTo, dateFrom, matchDays, l, q, call, leagueId, aoiId, teamId, matchDay]);
  useInfiniteScroll(onSearch, setItems, 0, limit);
  const filters = useMemo<FilterProp<"date" | "matchDay" | "matchDays" | "leagueFilter" | "areaOfInterestFilter" | "teamFilter">[]>(() => {
    return [
      {
        type: "areaOfInterestFilter",
        val: aoiId,
        setVal: setAoiId
      },
      {
        type: "leagueFilter",
        val: leagueId,
        setVal: setLeagueId
      },
      {
        type: "teamFilter",
        val: teamId,
        setVal: setTeamId
      },
      {
        type: "matchDay",
        val: matchDay,
        setVal: setMatchDay
      },
      {
        type: "matchDays",
        val: matchDays?.map(m => `${m}`) ?? [],
        setVal: setMatchDaysCont
      },
      {
        type: "date",
        label: "dateFrom",
        val: dateFrom,
        setVal: setDateFrom
      },
      {
        type: "date",
        label: "dateTo",
        val: dateTo,
        setVal: setDateTo
      }
    ];
  }, [aoiId, teamId, leagueId, matchDay, dateFrom, dateTo, matchDays, setMatchDaysCont]);
  const onDelete = useCallback(() => {
    if (del) {
      const id = del.id;
      call("deleteMatch", [id], "deleteFailedBcsSquadMatch").then(() => {
        setItems(itms => itms ? itms.filter(it => it.id !== id) : undefined);
        setDel(undefined);
      });
    }
  }, [del, setItems, call]);
  const onCancelUpload = useCallback(() => {
    setFile(undefined);
  }, []);
  const onFinishUpload = useCallback(() => {
    setFile(undefined);
    toggleImportCsv();
  }, [toggleImportCsv]);
  const navLive = useCallback(() => {
    navigator("/match/live");
  }, [navigator]);
  const onSaveBulk = useCallback((form: typeof bulkInitial) => {
    call("patchBulk", [selected, {
      startTime: form.startTime.length !== 0 ? form.startTime : undefined,
      matchDay: form.matchDay !== 0 ? form.matchDay : undefined
    }], "saveFailed", "saveSuccess").then((matches) => {
      matches.forEach((m) => {
        sthChanges(matchLeagueToMatchListElement(m));
        selected.forEach((sel) => {
          onCheck(sel);
        });
      });
    });
  }, [onCheck, selected, sthChanges, call]);
  const navEdit = useCallback(() => {
    navigator("/match/edit");
  }, [navigator]);
  const dispatch = useDispatch();
  const onDeleteMultiple = useCallback(() => {
    const insertedNum = parseInt(confirmDeleteMultiple, 10);
    if (selected.length === insertedNum) {
      call("bulkDeleteMatch", [selected]).then((tsk) => {
        saveTask(dispatch, {
          ...tsk,
          title: translate("deleteMultipleMatches", l) + ": " + selected.length
        });
      });
      setWrongInput(false);
      toggleDeleteMultiple();
      selected.forEach((sel) => {
        onCheck(sel);
      });
    } else {
      setWrongInput(true);
    }
  }, [onCheck, toggleDeleteMultiple, call, dispatch, l, selected, confirmDeleteMultiple]);
  const sthChangesCont = useCallback((add: MatchLeague) => {
    sthChanges(mapMatchLeagueToMatchList(add));
  }, [sthChanges]);
  if (!!file) {
    return (
      <CsvView file={file} className="mt-2" type={"match"}
               cancel={onCancelUpload} finish={onFinishUpload} />
    );
  }
  return (
    <React.Fragment>
      <Breadcrumps>
        <ClavaButton onClick={navEdit}
                     light className="mr-2">
          <FontAwesomeIcon icon={faPencil} size="lg"
                           className="mr-1" />
          {translate("editMode", l)}
        </ClavaButton>
        <ClavaButton onClick={navLive} light className="mr-2">
          <FontAwesomeIcon icon={faClock} className="mr-2" />
          {translate("matchLive", l)}
        </ClavaButton>
        <ClavaButton onClick={toggleCreate} light>
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          {translate("createMatch", l)}
        </ClavaButton>
      </Breadcrumps>
      <RoundedBlock>
        <ListBlockHeader title={"matchManagement"} onSearch={setQ} q={q}
                         icon={<MatchIcon />}>
          <ClavaButton onClick={toggleDeleteMultiple} lightSecondary
                       disabled={selected.length === 0} className="mr-2">
            <FontAwesomeIcon icon={faTrash} size="lg" className="mr-1" />
            {translate("deleteMultiple", l)}
          </ClavaButton>
          <ClavaButton onClick={toggleImportCsv} lightSecondary className="mr-2">
            <FontAwesomeIcon icon={faFileCsv} size="lg" className="mr-1" />
            {translate("importCsv", l)}
          </ClavaButton>
          <Filter filter={filters} sports={sports} uniqueId={"match-filters"}
                  className="mr-2" />
        </ListBlockHeader>
        {!!status && !!status.key && StatusComponent}


        <LoadingBlock isLoading={loading} smallLoading={filtered.length !== 0}>
          <BulkActions initial={bulkInitial} props={bulkProps} selected={selected}
                       onCancel={onCheckAll} onSave={onSaveBulk} dateProps={dateProps}
                       numberProps={numberProps} />
          <Table>
            <TableHeader sorted={sorted} onSort={onSort} items={headers}
                         onCheckAll={onCheckAll}
                         allChecked={allSelected} />
            <tbody>
            {filtered.map((fil) => (
              <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                        checked={selected.includes(fil.id)}
                        key={"matchManagement-" + fil.id} />
            ))}
            </tbody>
          </Table>
        </LoadingBlock>
      </RoundedBlock>
      {importCsv && !file && (
        <FileUploader as={1} type={"csv"} callback={setFile} close={toggleImportCsv} />
      )}
      <MessageBox type={"danger"} open={!!del} toggle={toggleDelete}
                  msg={translate("sureDelete", l, { "[name]": del ? `${showTranslated(del.team1.name, l)} - ${showTranslated(del.team2.name, l)}` : "" })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDelete}
                  btn2Text={translate("no", l)} />
      {create && (
        <MatchCreateModal onAdd={sthChangesCont} close={toggleCreate} />
      )}
      <MessageBox type={"warning"} open={deleteMultiple} toggle={toggleDeleteMultiple}
                  msg={translate("sureWantDeleteMultiple", l, { "[num]": selected.length.toString(10) })}
                  btn1Clb={onDeleteMultiple} btn1Text={translate("yes", l)}
                  btn2Clb={toggleDeleteMultiple} btn2Text={translate("no", l)}>
        <ClavaTextInput onChange={setConfirmDeleteMultiple} value={confirmDeleteMultiple}
                        error={wrongInput}
                        label={translate("howManyDelete", l)} type={"text"} />
      </MessageBox>
    </React.Fragment>
  );
};

export default MatchList;
