import React, { Dispatch, useCallback, useContext, useMemo } from "react";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import { translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import { NotificationCreate } from "./types";
import NotificationIcon from "../../components/Icons/NotificationIcon";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import NotificationParamCreate from "./NotificationParamCreate";

const NotificationsCreate: React.FC<{
  notification: NotificationCreate,
  setNotification: Dispatch<React.SetStateAction<NotificationCreate>>,
}> = ({ notification, setNotification }) => {
  const { l } = useContext(ClavaContext);

  const onChange = useCallback(<T extends keyof NotificationCreate>(text: string, key: T, secondKey: keyof NotificationCreate[T]) => {
    setNotification(not => ({
      ...not,
      [key]: {
        ...not[key],
        [secondKey]: text
      }
    }));
  }, [setNotification]);
  const onChangeTitleDe = useCallback((text: string) => {
    onChange(text, "title", "textDE");
  }, [onChange]);
  const onChangeTitleIt = useCallback((text: string) => {
    onChange(text, "title", "textIT");
  }, [onChange]);
  const onChangeTitleEn = useCallback((text: string) => {
    onChange(text, "title", "textEN");
  }, [onChange]);
  const onChangeBodyDe = useCallback((text: string) => {
    onChange(text, "body", "textDE");
  }, [onChange]);
  const onChangeBodyIt = useCallback((text: string) => {
    onChange(text, "body", "textIT");
  }, [onChange]);
  const onChangeBodyEn = useCallback((text: string) => {
    onChange(text, "body", "textEN");
  }, [onChange]);
  const paramKeys = useMemo(() => {
    return Object.keys(notification.data);
  }, [notification.data]);
  const onSaveParam = useCallback((key: string, value: string, oldKey?: string) => {
    setNotification(not => {
      const newNot = {
        ...not,
        data: {
          ...not.data,
          [key]: value
        }
      };
      if (oldKey && oldKey !== key)
        delete newNot.data[oldKey];
      return newNot;
    });
  }, [setNotification]);
  return (
    <React.Fragment>
      <ListBlockHeader title={"notificationsCreate"}
                       icon={<NotificationIcon />}>

      </ListBlockHeader>
      <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">
        <ClavaTextInput onChange={onChangeTitleDe}
                        value={notification.title.textDE} label={translate("title", l) + " DE"}
                        className="mx-2" />
        <ClavaTextInput onChange={onChangeTitleIt}
                        value={notification.title.textIT} label={translate("title", l) + " IT"}
                        className="mx-2" />
        <ClavaTextInput onChange={onChangeTitleEn}
                        value={notification.title.textEN} label={translate("title", l) + " EN"}
                        className="mx-2" />
      </div>
      <div className="flex flex-row items-center mx-[-8px] my-4">
        <ClavaTextInput onChange={onChangeBodyDe}
                        value={notification.body.textDE} label={translate("body", l) + " DE"}
                        className="mx-2" />
        <ClavaTextInput onChange={onChangeBodyIt}
                        value={notification.body.textIT} label={translate("body", l) + " IT"}
                        className="mx-2" />
        <ClavaTextInput onChange={onChangeBodyEn}
                        value={notification.body.textEN} label={translate("body", l) + " EN"}
                        className="mx-2" />

      </div>
      {paramKeys.map(paramKey => (
        <NotificationParamCreate onSave={onSaveParam} value={notification.data[paramKey]}
                                 key={paramKey + "-" + notification.data[paramKey]}
                                 paramKey={paramKey} />
      ))}
      <NotificationParamCreate onSave={onSaveParam} />
    </React.Fragment>
  );
};

export default NotificationsCreate;