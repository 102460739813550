import { PlayerPosition, TaskInfo } from "../../client/api";
import { TranslatorKeys } from "../../config/translator";
import { ValueStore } from "../constants";
import { IDType, TaskExt } from "../../config/types";

export enum GeneralActionTypes {
  FETCH_PLAYER_POSITIONS = "@@general/FETCH_PLAYER_POSITIONS",
  FETCH_PLAYER_POSITIONS_SUCCESS = "@@general/FETCH_PLAYER_POSITIONS_SUCCESS",
  FETCH_PLAYER_POSITIONS_ERROR = "@@general/FETCH_PLAYER_POSITIONS_ERROR",
  LOAD_TASKS = "@@general/LOAD_TASKS",
  ADD_TASK = "@@general/ADD_TASK",
  REMOVE_TASK = "@@general/REMOVE_TASK",
  FETCH_TASK = "@@general/FETCH_TASK",
  FETCH_TASK_SUCCESS = "@@general/FETCH_TASK_SUCCESS",
  FETCH_TASK_ERROR = "@@general/FETCH_TASK_ERROR",
}

export type GeneralActions =
  | {
  type: GeneralActionTypes.FETCH_PLAYER_POSITIONS
    | GeneralActionTypes.FETCH_TASK
}
  | { type: GeneralActionTypes.FETCH_PLAYER_POSITIONS_SUCCESS; payload: PlayerPosition[] }
  | { type: GeneralActionTypes.FETCH_TASK_SUCCESS; payload: TaskInfo }
  | { type: GeneralActionTypes.LOAD_TASKS; payload: TaskExt[] }
  | { type: GeneralActionTypes.ADD_TASK; payload: TaskExt }
  | { type: GeneralActionTypes.REMOVE_TASK; payload: string }
  | {
  type: GeneralActionTypes.FETCH_PLAYER_POSITIONS_ERROR | GeneralActionTypes.FETCH_TASK_ERROR;
  payload: TranslatorKeys
};

export declare type AllActions = GeneralActions;

export declare type PayloadAction<T extends AllActions> = T extends {
    type: T["type"];
    payload: any;
  }
  ? T
  : never;

export declare type InitAction<T extends AllActions> = T extends {
    type: T["type"];
  }
  ? T
  : never;

export type WithoutResponse<T extends ValueStore<any> | any> =
  T extends ValueStore<any>
    ? {
      id: IDType;
      date?: number;
    }
    : false;
