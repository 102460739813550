/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthResponse } from '../models/AuthResponse';
import type { LoginIdentity } from '../models/LoginIdentity';
import type { LoginInformation } from '../models/LoginInformation';
import type { Password } from '../models/Password';
import type { PasswordChange } from '../models/PasswordChange';
import type { PasswordForgot } from '../models/PasswordForgot';
import type { UserLogin } from '../models/UserLogin';
import type { UserLoginTel } from '../models/UserLoginTel';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthenticationService {
    /**
     * Login With Email Password
     * @param email
     * @param password
     * @returns AuthResponse Successful Response
     * @throws ApiError
     */
    public static loginWithEmailPasswordAuthLoginPost(
        email: string,
        password: string,
    ): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/login',
            query: {
                'email': email,
                'password': password,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Secure Login With Email Password
     * @param requestBody
     * @returns AuthResponse Successful Response
     * @throws ApiError
     */
    public static secureLoginWithEmailPasswordAuthSecureLoginPost(
        requestBody: UserLogin,
    ): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/secure_login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Secure Login With Tel Password
     * @param requestBody
     * @returns AuthResponse Successful Response
     * @throws ApiError
     */
    public static secureLoginWithTelPasswordAuthSecureLoginTelPost(
        requestBody: UserLoginTel,
    ): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/secure_login_tel',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Login With Tel And Sms
     * @param tel
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static loginWithTelAndSmsAuthLoginWithTelAndSmsTelPost(
        tel: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/login_with_tel_and_sms/{tel}',
            path: {
                'tel': tel,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Login With Mail And Sms
     * @param mail
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static loginWithMailAndSmsAuthLoginWithMailAndSmsMailPost(
        mail: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/login_with_mail_and_sms/{mail}',
            path: {
                'mail': mail,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Refresh
     * @returns AuthResponse Successful Response
     * @throws ApiError
     */
    public static refreshAuthRefreshPost(): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/refresh',
        });
    }
    /**
     * Get Freshness Token
     * @param requestBody
     * @returns AuthResponse Successful Response
     * @throws ApiError
     */
    public static getFreshnessTokenAuthFreshnessTokenPost(
        requestBody: Password,
    ): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/freshness_token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Check Freshness
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static checkFreshnessAuthCheckFreshnessGet(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/check_freshness',
        });
    }
    /**
     * Get Login Information
     * @param telOrMail
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getLoginInformationAuthLoginInformationTelOrMailGet(
        telOrMail: string,
    ): CancelablePromise<(LoginInformation | null)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/login_information/{tel_or_mail}',
            path: {
                'tel_or_mail': telOrMail,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Password Forgot Sms
     * @param mailOrTel
     * @returns any Successful Response
     * @throws ApiError
     */
    public static passwordForgotSmsAuthPasswordForgotSmsMailOrTelPost(
        mailOrTel: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/password_forgot_sms/{mail_or_tel}',
            path: {
                'mail_or_tel': mailOrTel,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Password Forgot Mail
     * @param mailOrTel
     * @returns any Successful Response
     * @throws ApiError
     */
    public static passwordForgotMailAuthPasswordForgotMailMailOrTelPost(
        mailOrTel: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/password_forgot_mail/{mail_or_tel}',
            path: {
                'mail_or_tel': mailOrTel,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Check Password Forgot Code
     * @param emailOrTel
     * @param code
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static checkPasswordForgotCodeAuthCheckPasswordForgotCodeEmailOrTelPost(
        emailOrTel: string,
        code: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/check_password_forgot_code/{email_or_tel}',
            path: {
                'email_or_tel': emailOrTel,
            },
            query: {
                'code': code,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Confirm Password Forgot
     * This endpoint checks if the code is correct and changes the password
     * :param session:
     * :param email_or_tel:
     * :param password_forgot:
     * :param _:
     * :return:
     * @param emailOrTel
     * @param requestBody
     * @returns AuthResponse Successful Response
     * @throws ApiError
     */
    public static confirmPasswordForgotAuthPasswordForgotConfirmEmailOrTelPost(
        emailOrTel: string,
        requestBody: PasswordForgot,
    ): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/password_forgot_confirm/{email_or_tel}',
            path: {
                'email_or_tel': emailOrTel,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Change Password
     * @param requestBody
     * @returns AuthResponse Successful Response
     * @throws ApiError
     */
    public static changePasswordAuthChangePasswordPost(
        requestBody: PasswordChange,
    ): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/change_password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Login Identity
     * @param requestBody
     * @returns AuthResponse Successful Response
     * @throws ApiError
     */
    public static patchLoginIdentityAuthLoginIdentityPatch(
        requestBody: LoginIdentity,
    ): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/auth/login_identity',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
