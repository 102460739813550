import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { LogDetails, LogEntry } from "../../client/api";
import { translate, TranslatorKeys } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import ClavaSelect from "../../components/Atoms/ClavaSelect";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { useNavigate } from "react-router";
import { ClavaSelectItems } from "../../config/types";
import Table from "../../components/Table/Table";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";
import { TableRowItem, useList } from "../../hooks/useList";
import { formatDate } from "../../config/utils";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import LogIcon from "../../components/Icons/LogIcon";
import MessageBox from "../../components/Atoms/MessageBox";
import JsonSyntaxHighlighter from "../../components/Atoms/JsonSyntaxHighlighter";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import { endpoints } from "../../client/api/endpoints/endpoints";

const mappedEndpoints = endpoints.filter(e => e.method !== "GET").map<ClavaSelectItems>((e) => ({
  key: `${e.method}-${e.path}`,
  label: e.summary
}));
const headers: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "user"
  },
  {
    label: "action"
  },
  {
    label: "time"
  },
  {
    label: "entityType"
  },
  {
    label: "entityId"
  },
  {
    label: "details"
  },
  {
    label: "statusCode"
  },
  {
    label: "duration"
  }
];

function hidePw(details: LogDetails): LogDetails {
  if (details.request_payload && "password" in details.request_payload && details.request_payload.password) {
    return {
      ...details,
      request_payload: {
        ...details.request_payload,
        password: "***"
      }
    };
  }
  return details;
}

const AuditLog = () => {
  const { l, user } = useContext(ClavaContext);
  const [pages, setPages] = useState(0);
  const page = useRef(1);
  const [userId, setUserId] = useState<string>("");
  const [q, setQ] = useState<string>("");
  const [endpoint, setEndpoint] = useState<string>();
  const [showDetails, setShowDetails] = useState<LogDetails>();
  const mapper = useCallback((item: LogEntry): TableRowItem => {
    return {
      search: `${item.action} ${item.id}`.toLowerCase(),
      id: -1,
      items: [
        {
          label: item.id
        }, {
          label: item.userId ? item.userId.toString(10) : " - "
        }, {
          label: item.action
        }, {
          label: formatDate(new Date(item.timestamp), l, false, false, false, true)
        }, {
          label: item.entity_type
        }, {
          label: item.entity_id
        }, {
          buttons: [
            {
              label: `${item.details.method}: ${item.details.path}`,
              onClick: () => {
                if (user.id === 4)
                  setShowDetails(item.details);
                else {
                  setShowDetails(hidePw(item.details));
                }
              },
              type: "secondary"
            }
          ]
        }, {
          pill: {
            type: item.status_code === 200 ? "success" : "danger",
            text: item.status_code.toString(10) as TranslatorKeys
          }
        }, {
          label: item.duration.toString(10)
        }]
    };
  }, [l, user.id]);
  const { call, loading } = useServer(true);
  const {
    setItems,
    allSelected,
    onCheckAll,
    onCheck,
    filtered
  } = useList(mapper, 80);
  const onSearch = useCallback(() => {
    const usrId = parseInt(userId, 10);
    const realEndpoint = endpoints.find((e) => `${e.method}-${e.path}` === endpoint);
    call("getLog", [
      Number.isNaN(usrId) ? undefined : usrId,
      realEndpoint ? realEndpoint.method as "POST" | "DELETE" | "PATCH" | "PUT" : undefined,
      realEndpoint ? realEndpoint.path.replace(/{[^}]+}/g, "").replace(/\/+$/, "") : q === "" ? undefined : q,
      page.current]).then(
      (res) => {
        page.current = res.page;
        setItems(res.logs);
        setPages(Math.floor(res.total_count / res.page_size));
      });
  }, [call, endpoint, userId, setItems, q]);
  useEffect(() => {
    const t = setTimeout(() => {
      page.current = 1;
      onSearch();
    }, 300);
    return () => {
      clearTimeout(t);
    };
  }, [onSearch]);
  const toggleDetails = useCallback(() => {
    setShowDetails(undefined);
  }, []);
  const navigator = useNavigate();
  const onNavBack = useCallback(() => {
    navigator("/advertising");
  }, [navigator]);
  const pageBtns = useMemo<{ num: number, active: boolean }[]>(() => {
    if (pages <= 1)
      return [];
    if (pages <= 8)
      return Array(pages).fill(0).map((_, i) => {
        return {
          num: i + 1,
          active: (i + 1) === page.current
        };
      });
    const lowerBound = page.current < 7 ? 7 : 3;
    const upperBound = page.current > pages - 7 ? 3 : 6;
    return Array(10).fill(0).map((_, i) => {
      if (i < lowerBound) {
        return {
          num: i + 1,
          active: (i + 1) === page.current
        };
      }
      if (i > upperBound) {
        return {
          num: pages - (9 - i),
          active: pages - (9 - i) === page.current
        };
      }
      const num = page.current - (4 - i);
      return {
        num: num,
        active: num === page.current
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages, filtered]);
  return (
    <>
      <Breadcrumps>
        <ClavaButton onClick={onNavBack} lightSecondary
                     className="mr-2">{translate("back", l)}</ClavaButton>

      </Breadcrumps>
      <RoundedBlock>

        <ListBlockHeader title={"log"} icon={<LogIcon />}>
          <ClavaSelect uniqueId={"method"} items={mappedEndpoints} withNone withSearch
                       onChange={setEndpoint}
                       value={endpoint}
                       label={translate("method", l)} className="mr-1" />
          <div className="mr-1">
            <ClavaTextInput onChange={setQ}
                            value={q}
                            label={translate("search", l)} />
          </div>
          <div className="mr-1">
            <ClavaTextInput onChange={setUserId}
                            value={userId}
                            label={translate("user", l) + " Id"} />
          </div>
        </ListBlockHeader>
        <LoadingBlock isLoading={loading} className="p-2">
          <Table>
            <TableHeader items={headers} onCheckAll={onCheckAll}
                         allChecked={allSelected} />
            <tbody>
            {filtered.map((fil) => (

              <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                        key={"log-list-" + fil.search}
                        checked={false} />
            ))}
            </tbody>
          </Table>
          <div className="flex flex-row items-center justify-end">
            {pageBtns.map((btn, i) => (
              <React.Fragment key={"page-" + btn.num}>
                {i !== 0 && pageBtns[i - 1].num !== btn.num - 1 ? (
                  <span className="mx-1">...</span>
                ) : null}
                <ClavaButton className="mx-1" onClick={() => {
                  page.current = btn.num;
                  onSearch();
                }} secondary={!btn.active} size="fit">
                  {btn.num}
                </ClavaButton>
              </React.Fragment>
            ))}
          </div>
        </LoadingBlock>
        <MessageBox type={"info"} open={!!showDetails} toggle={toggleDetails}
                    className={"!max-w-full"}
                    msg={translate("details", l)} btn1Clb={toggleDetails}
                    btn1Text={translate("close", l)}>

          {!!showDetails ? (
            <JsonSyntaxHighlighter obj={showDetails}
                                   className="max-w-full whitespace-pre-wrap overflow-scroll" />
          ) : ""}
        </MessageBox>
      </RoundedBlock>
    </>
  );
};

export default AuditLog;
