import React, { useCallback, useContext, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown, faChevronCircleUp } from "@fortawesome/pro-light-svg-icons";
import {
  faCircleDown,
  faCircleSort,
  faCircleUp,
  faTriangleExclamation
} from "@fortawesome/pro-solid-svg-icons";
import { Punishment, Standing, StandingList } from "../../client/api";
import { IDType } from "../../config/types";
import { ClavaContext } from "../../config/contexts";
import ClavaImage from "../../components/Atoms/ClavaImage";
import { showTranslated, translate } from "../../config/translator";
import useBoolState from "../../hooks/useBoolState";

type TableStateType = {
  id: IDType;
  state: "WIN" | "DRAW" | "LOSS";
};
const Line: React.FC<{
  standing: Standing;
  punishments: Punishment[];
  state?: TableStateType,
  onAddPunishment: (teamId: IDType, leagueId: IDType) => void
}> = ({
        standing, onAddPunishment,
        state, punishments
      }) => {
  const { l } = useContext(ClavaContext);
  const [open, toggleOpen] = useBoolState(false);
  const togglePunishment = useCallback(() => {
    onAddPunishment(standing.team.id, standing.league.id);
  }, [standing.team.id, onAddPunishment, standing.league.id]);
  const pbStyle = useMemo(() => {
    if (open) return "pb-14";
    return "";
  }, [open]);

  return (
    <tr className="border-b border-b-light-gray dark:border-b-light-gray-dark relative">
      <td className={`relative text-right pr-3 py-2 ${pbStyle}`}>
        {state ? (
          <FontAwesomeIcon
            icon={
              state.state === "WIN"
                ? faCircleUp
                : state.state === "LOSS"
                  ? faCircleDown
                  : faCircleSort
            }
            className={`absolute w-3 h-3 rounded-full top-2 left-2 ${
              state.state === "WIN"
                ? "text-win"
                : state.state === "LOSS"
                  ? "text-loss"
                  : "text-orange"
            }`}
          />
        ) : (
          <div />
        )}
        <span className="text-right">{standing.placement}</span>
      </td>
      <td className={`py-2 ${pbStyle}`}>
        <div className="flex flex-row items-center justify-start">
          <ClavaImage
            image={standing.team.emblem}
            width={24}
            className="mr-2"
          />
          <span>{showTranslated(standing.team.name, l) + (punishments.length !== 0 ? " *" : "")}</span>
        </div>
      </td>
      <td className={`text-right py-2 ${pbStyle}`}>{standing.matchesPlayed}</td>
      <td className={`text-right py-2 ${pbStyle}`}>
        {standing.goalsOut - standing.goalsIn}
      </td>
      <td className={`text-right py-2 ${pbStyle}`}>{standing.points}</td>
      <td className={`text-right py-2 pr-4 ${pbStyle}`}>
        <button type="button" onClick={toggleOpen}>
          <FontAwesomeIcon
            icon={open ? faChevronCircleUp : faChevronCircleDown}
            className={`w-4 h-4 ${open ? "!text-primary" : ""}`}
          />
        </button>
        <button type="button" onClick={togglePunishment} className="ml-4">
          <FontAwesomeIcon icon={faTriangleExclamation} className={`w-4 h-4 text-red`} />
        </button>

        <div
          className={`absolute bottom-0 left-0 bg-bg dark:bg-bg-dark right-0 h-12 flex flex-row items-center justify-evenly ${
            open ? "" : "hidden"
          }`}
        >
          <span className="bg-win-30 rounded-lg py-0.5 px-8">{`${translate(
            "wins",
            l
          )}: ${standing.wins}`}</span>
          <span className="bg-loss-30 rounded-lg py-0.5 px-8">{`${translate(
            "losses",
            l
          )}: ${standing.losses}`}</span>
          <span
            className="bg-bg-secondary dark:bg-bg-secondary-dark rounded-lg py-0.5 px-8">{`${translate(
            "draws",
            l
          )}: ${standing.draws}`}</span>
          <span
            className="bg-bg-secondary dark:bg-bg-secondary-dark rounded-lg py-0.5 px-8">{`${translate(
            "goalsOut",
            l
          )}: ${standing.goalsOut}`}</span>
          <span
            className="bg-bg-secondary dark:bg-bg-secondary-dark rounded-lg py-0.5 px-8">{`${translate(
            "goalsIn",
            l
          )}: ${standing.goalsIn}`}</span>
          {punishments.length !== 0 && (
            <span
              className="bg-bg-secondary dark:bg-bg-secondary-dark rounded-lg py-0.5 px-2">{punishments.map(p => showTranslated(p.cause, l) + ": -" + p.deduction).join("<br />")}</span>
          )}
        </div>
      </td>
    </tr>
  );
};


const SingleTable: React.FC<{
  standing: StandingList,
  punishments: Punishment[],
  onAddPunishment: (teamId: IDType, leagueId: IDType) => void
}> = ({
        standing, punishments, onAddPunishment
      }) => {
  const { l } = useContext(ClavaContext);
  const selectedStanding = useMemo(() => {

    return standing.sort((a, b) => a.placement - b.placement);
  }, [standing]);
  return (
    <div className="table-container">
      <div className="min-w-full">
        <table className="min-w-full">
          <thead>
          <tr>
            <th className="text-right pr-3 py-2">#</th>
            <th className="text-left py-2">{translate("teams", l)}</th>
            <th className="text-right py-2">
              {translate("matchesShort", l)}
            </th>
            <th className="text-right py-2">
              {translate("difference", l)}
            </th>
            <th className="text-right py-2">{translate("points", l)} </th>
            <th className="py-2 pr-4"></th>
          </tr>
          </thead>
          <tbody>
          {selectedStanding.map((st) => (
            <Line
              onAddPunishment={onAddPunishment}
              standing={st}
              punishments={punishments.filter(p => p.teamId === st.team.id)}
              key={`stand${st.team.id}`}
            />
          ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default SingleTable;
// r elo ad
