import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { refreshTask, removeTask } from "../../redux/actions/generalActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { TaskExt } from "../../config/types";
import EventsSocket from "../../client/Websockets/events";

const SingleTaskView: React.FC<{ task: TaskExt }> = ({ task }) => {
  const dispatch = useDispatch();
  const { tasksResponse } = useSelector<RootState, RootState["general"]>((state) => state.general);
  const thisInfo = useMemo(() => {
    return tasksResponse.find(t => t.id === task.id);
  }, [task.id, tasksResponse]);
  const doRefreshTask = useCallback(() => {
    refreshTask(dispatch, task.id);
  }, [dispatch, task.id]);
  useEffect(() => {
    refreshTask(dispatch, task.id);
    EventsSocket.setTaskHandler(task, doRefreshTask);
  }, [
    task, dispatch, doRefreshTask
  ]);
  const onRemove = useCallback(() => {
    removeTask(dispatch, task.id);
    EventsSocket.removeTaskHandler(task.id);
  }, [task.id, dispatch]);
  if (!thisInfo)
    return null;
  return (
    <div className="relative max-w-xl bg-primary-80 p-4 border-primary border rounded-xl">
      <div className="py-2 pr-14">
        <span className="font-bold">{task.title}</span>
      </div>
      <span className="font-semibold">{thisInfo.status}</span><br />
      <span>{thisInfo.result}</span>
      <button
        className="absolute w-10 h-10 top-1 right-1 flex-row items-center justify-center rounded-lg bg-[#fff] border border-light-gray dark:border-light-gray-dark hover:bg-red hover:text-white"
        onClick={onRemove}>
        <FontAwesomeIcon icon={faTrash} />
      </button>
    </div>
  );
};

export default SingleTaskView;
