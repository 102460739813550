import React, { useCallback, useEffect, useState } from "react";
import "./css/style.css";
import { fb, initFb } from "./config/firebase";
import { ClavaRootContextType } from "./config/types";
import { AS_THEME } from "./config/constants";
import { ClavaRootContext } from "./config/contexts";
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import Wrapper from "./Wrapper";
import Dashboard from "./screens/Dashboard/Dashboard";
import LeagueList from "./screens/League/LeagueList";
import { RouterProvider } from "react-router";
import Loading from "./components/Atoms/Loading";
import AdvertisingList from "./screens/Advertising/AdvertisingList";
import "intl/locale-data/jsonp/de-DE";
import "intl/locale-data/jsonp/en-US";
import "intl/locale-data/jsonp/it-IT";
import NotFound from "./screens/NotFound";
import LeagueReorder from "./screens/League/LeagueReorder";
import AdvertisingCreate from "./screens/Advertising/AdvertisingCreate";
import TeamList from "./screens/Team/TeamList";
import LeagueEdit from "./screens/League/LeagueEdit";
import AdvertisingEdit from "./screens/Advertising/AdvertisingEdit";
import TeamEdit from "./screens/Team/TeamEdit";
import LeagueCategories from "./screens/League/LeagueCategories";
import MatchList from "./screens/Match/MatchList";
import MatchEdit from "./screens/Match/MatchEdit";
import UserList from "./screens/User/UserList";
import TournamentList from "./screens/Tournament/TournamentList";
import TournamentEdit from "./screens/Tournament/TournamentEdit";
import BlogList from "./screens/Blog/BlogList";
import BlogEdit from "./screens/Blog/BlogEdit";
import BlogCreateView from "./screens/Blog/BlogCreateView";
import { store } from "./redux";
import { Provider } from "react-redux";
import MatchLive from "./screens/Match/MatchLive";
import MatchEditList from "./screens/Match/MatchEditList";
import Tables from "./screens/Tables/Tables";
import FeedList from "./screens/Feed/FeedList";
import AdvertiserStatistics from "./screens/Advertising/AdvertiserStatistics";
import AuditLog from "./screens/Log/AuditLog";
import NotificationsPage from "./screens/Notifications/NotificationsPage";
import GPTPage from "./screens/GPT/GPTPage";

const t = window.localStorage.getItem(AS_THEME);
const rootClasses = document.body.className;
document.body.className = `${rootClasses} ${t}`;

function App() {
  const [rootContext, setRootContext] = useState<ClavaRootContextType>({
    fbToken: "",
    initialized: false,
    theme: t === "dark" || t === "light" ? t : "light"
  });
  const setThemeCont = useCallback((newTheme: "dark" | "light") => {
    window.localStorage.setItem(AS_THEME, newTheme);
    document.body.className = `${rootClasses} ${newTheme}`;
    setRootContext(rc => ({
      ...rc,
      theme: newTheme
    }));
  }, []);
  useEffect(() => {
    initFb().then(() => {
      const fbToken = fb().getFirebaseToken();

      setRootContext(rc => ({
        theme: rc.theme,
        fbToken,
        initialized: true
      }));

    });
  }, []);
  const router = createBrowserRouter(createRoutesFromElements(
    <Route element={<Wrapper setTheme={setThemeCont} />}>
      <Route index element={<Dashboard />} />
      <Route path="/home" element={<Dashboard />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/league" element={<LeagueList />} />
      <Route path="/league/reorder" element={<LeagueReorder />} />
      <Route path="/league/categories" element={<LeagueCategories />} />
      <Route path="/league/:leagueId" element={<LeagueEdit />} />
      <Route path="/advertising" element={<AdvertisingList />} />
      <Route path="/advertising/create" element={<AdvertisingCreate />} />
      <Route path="/advertising/:adId" element={<AdvertisingEdit />} />
      <Route path="/advertising/stats" element={<AdvertiserStatistics />} />
      <Route path="/team" element={<TeamList />} />
      <Route path="/team/:teamId" element={<TeamEdit />} />
      <Route path="/match" element={<MatchList />} />
      <Route path="/match/live" element={<MatchLive />} />
      <Route path="/match/edit" element={<MatchEditList />} />
      <Route path="/match/:matchId" element={<MatchEdit />} />
      <Route path="/user" element={<UserList />} />
      <Route path="/tournament" element={<TournamentList />} />
      <Route path="/tournament/:tournamentId" element={<TournamentEdit />} />
      <Route path="/news" element={<BlogList />} />
      <Route path="/news/create" element={<BlogCreateView />} />
      <Route path="/news/:blogId" element={<BlogEdit />} />
      <Route path="/live-tables" element={<Tables />} />
      <Route path="/feed" element={<FeedList />} />
      <Route path="/log" element={<AuditLog />} />
      <Route path="/notifications" element={<NotificationsPage />} />
      <Route path="/gpt" element={<GPTPage />} />
      <Route path="/*" element={<NotFound />} />
    </Route>
  ));
  if (!rootContext.initialized) {
    return <Loading fullscreen />;
  }
  return (
    <ClavaRootContext.Provider value={rootContext}>
      <Provider store={store}>
        <RouterProvider router={router}></RouterProvider>
      </Provider>
    </ClavaRootContext.Provider>
  );
}

export default App;
