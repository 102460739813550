/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_send_notification_to_user_notification_user_post } from '../models/Body_send_notification_to_user_notification_user_post';
import type { Body_send_notifications_to_user_search_notification_user_notifications_post } from '../models/Body_send_notifications_to_user_search_notification_user_notifications_post';
import type { NotificationRequest } from '../models/NotificationRequest';
import type { ScopeEnum } from '../models/ScopeEnum';
import type { Task } from '../models/Task';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NotificationService {
    /**
     * Send Notification To User
     * @param requestBody
     * @returns number Successful Response
     * @throws ApiError
     */
    public static sendNotificationToUserNotificationUserPost(
        requestBody: Body_send_notification_to_user_notification_user_post,
    ): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/notification/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Send Notification To User Zapier
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendNotificationToUserZapierNotificationUserZapierPost(
        requestBody: NotificationRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/notification/user_zapier',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Send Notifications To User Search
     * @param query
     * @param requestBody
     * @param scopes
     * @param areaOfInterestIds
     * @param insidersOfTeams
     * @param isInsider
     * @param isPlayer
     * @param languages
     * @param anonymous
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static sendNotificationsToUserSearchNotificationUserNotificationsPost(
        query: string,
        requestBody: Body_send_notifications_to_user_search_notification_user_notifications_post,
        scopes?: (Array<ScopeEnum> | null),
        areaOfInterestIds?: (Array<number> | null),
        insidersOfTeams?: (Array<number> | null),
        isInsider?: (boolean | null),
        isPlayer?: (boolean | null),
        languages?: (Array<string> | null),
        anonymous?: (boolean | null),
    ): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/notification/user_notifications',
            query: {
                'query': query,
                'scopes': scopes,
                'area_of_interest_ids': areaOfInterestIds,
                'insiders_of_teams': insidersOfTeams,
                'is_insider': isInsider,
                'is_player': isPlayer,
                'languages': languages,
                'anonymous': anonymous,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User Notification Count
     * @param query
     * @param scopes
     * @param areaOfInterestIds
     * @param insidersOfTeams
     * @param isInsider
     * @param isPlayer
     * @param languages
     * @param anonymous
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getUserNotificationCountNotificationUserNotificationCountGet(
        query: string,
        scopes?: (Array<ScopeEnum> | null),
        areaOfInterestIds?: (Array<number> | null),
        insidersOfTeams?: (Array<number> | null),
        isInsider?: (boolean | null),
        isPlayer?: (boolean | null),
        languages?: (Array<string> | null),
        anonymous?: (boolean | null),
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/notification/user_notification_count',
            query: {
                'query': query,
                'scopes': scopes,
                'area_of_interest_ids': areaOfInterestIds,
                'insiders_of_teams': insidersOfTeams,
                'is_insider': isInsider,
                'is_player': isPlayer,
                'languages': languages,
                'anonymous': anonymous,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
