import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {
  AreaOfInterest,
  LanguageLocaleEnum,
  LeagueListElement,
  MatchLeague,
  MatchLeagueCreateNew,
  TeamListElement
} from "../../client/api";
import {ClavaContext} from "../../config/contexts";
import {useFormData} from "../../hooks/useFormData";
import useServer from "../../hooks/useServer";
import {translate} from "../../config/translator";
import LeagueSelect from "../../components/Atoms/Common/LeagueSelect";
import AoiSelect from "../../components/Atoms/Common/AoiSelect";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import ClavaSelect, {mapTranslatableToItem} from "../../components/Atoms/ClavaSelect";
import AsideModal from "../../components/Layout/AsideModal";
import ClavaDateInput from "../../components/Atoms/ClavaDateInput";
import {formatDate} from "../../config/utils";

const emptyMatch: MatchLeagueCreateNew = {
    matchDay: 0,
    externalId: "",
    locationId: undefined,
    originalStartTime: "",
    refereeId: undefined,
    team1Id: -1,
    team2Id: -1,
};

const MatchCreateModal: React.FC<{
    onAdd: (league: MatchLeague) => void,
    close: () => void
}> = ({onAdd, close}) => {
    const {l, aoi} = useContext(ClavaContext);
    const form = useFormData(emptyMatch,
        ["matchDay", "team1Id", "team2Id"],
        {},
        [],
        [],
        ["matchDay"],
        [],
        [],
        ["matchDay", "team2Id", "team1Id"]
    );
    const {call, StatusComponent, status} = useServer(true);
    const [startTime, setStartTime] = useState(new Date());
    const [team1, setTeam1] = useState<TeamListElement>();
    const [team2, setTeam2] = useState<TeamListElement>();
    const [teams, setTeams] = useState<TeamListElement[]>();
    const [league, setLeague] = useState<LeagueListElement>();
    const [aoiF, setAoiF] = useState<AreaOfInterest>();
    const onSave = useCallback(() => {
        if (!form.nothingChanged && league) {
            call("createMatch", [league.id, {
                matchDay: form.matchDay,
                originalStartTime: formatDate(startTime, LanguageLocaleEnum.DE, true),
                team1Id: form.team1Id,
                team2Id: form.team2Id
            }], "saveFailed", "saveSuccessCanCreateNewMatch").then((res) => {
                onAdd(res);
            });
        }
    }, [form, onAdd, startTime, call, league]);
    useEffect(() => {
        if (league) {
            call("getTeamsOfLeague", [league.id]).then(setTeams);
        }
    }, [league, call]);
    const teamItems = useMemo(() => {
        if (!teams) return [];
        return teams.map(mapTranslatableToItem(l));
    }, [l, teams]);
    const setTeam1Cont = useCallback((val: string | undefined) => {
        if (val) {
            const id = parseInt(val, 10);
            const team = teams?.find(t => t.id === id);
            if (team) {
                setTeam1(team);
                form.setFormData(fd => ({
                    ...fd,
                    team1Id: team.id
                }));
            }
        } else {
            setTeam1(undefined);
            form.setFormData(fd => ({
                ...fd,
                team1Id: -1
            }));
        }
    }, [form, teams]);
    const setTeam2Cont = useCallback((val: string | undefined) => {
        if (val) {
            const id = parseInt(val, 10);
            const team = teams?.find(t => t.id === id);
            if (team) {
                setTeam2(team);
                form.setFormData(fd => ({
                    ...fd,
                    team2Id: team.id
                }));
            }
        } else {
            setTeam2(undefined);
            form.setFormData(fd => ({
                ...fd,
                team2Id: -1
            }));
        }
    }, [form, teams]);
    return (
        <AsideModal close={close} title={translate("createMatch", l)} saveClb={onSave}
                    saveDisabled={!form.allChanged}>
            {status && (!status.success || status.key === "saveSuccessCanCreateNewMatch") ? StatusComponent : null}
            <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">
                <AoiSelect selected={aoiF} setSelected={setAoiF} className="flex-1 mx-2"/>
                <LeagueSelect setSelected={setLeague} selected={league} className="flex-1 mx-2"
                              selectedAoi={aoiF?.id ?? aoi}/>
            </div>

            <div className="flex flex-row items-center justify-between mx-[-8px] mt-2 my-4">

                <ClavaTextInput onChange={form.onChangeMatchDay} value={form.matchDayVal}
                                label={translate("matchDay", l)} className="flex-1 mx-2"/>
                <ClavaDateInput value={startTime} onChange={setStartTime} className="flex-1 mx-2"
                                name={"matchStartTime"}
                                label={translate("startTime", l)} type={"datetime"}/>
            </div>
            <div className="flex flex-row items-center justify-between mx-[-8px] mt-2 my-4">
                <ClavaSelect uniqueId={"team1-search"} items={teamItems} value={team1?.id?.toString(10)}
                             onChange={setTeam1Cont} withSearch label={translate("team1", l)}
                             className="flex-1 mx-2"/>
                <ClavaSelect uniqueId={"team1-search"} items={teamItems} value={team2?.id?.toString(10)}
                             onChange={setTeam2Cont} withSearch label={translate("team2", l)}
                             className="flex-1 mx-2"/>
            </div>
        </AsideModal>
    );
};

export default MatchCreateModal;
