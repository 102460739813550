import { ClavaRoute } from "./types";
import { ScopeEnum } from "../client/api";

export const HomeRoute: ClavaRoute = {
  name: "dashboard",
  path: "/dashboard",
  rights: [ScopeEnum.BLOGGER, ScopeEnum.MANAGER, ScopeEnum.MODERATOR]
};
export const NotFoundRoute: ClavaRoute = {
  name: "notFound",
  path: "/*",
  rights: []
};
export const routes: (ClavaRoute | string)[] = [
  HomeRoute,
  {
    name: "league",
    path: "/league",
    rights: [ScopeEnum.MANAGER, ScopeEnum.MODERATOR]
  },
  {
    name: "tournament",
    path: "/tournament",
    rights: [ScopeEnum.MANAGER, ScopeEnum.MODERATOR]
  },
  {
    name: "match",
    path: "/match",
    rights: [ScopeEnum.MANAGER, ScopeEnum.MODERATOR]
  },
  {
    name: "team",
    path: "/team",
    rights: [ScopeEnum.MANAGER, ScopeEnum.MODERATOR]
  },
  {
    name: "user",
    path: "/user",
    rights: [ScopeEnum.MODERATOR]
  },
  {
    name: "liveTables",
    path: "/live-tables",
    rights: [ScopeEnum.MANAGER, ScopeEnum.MODERATOR]
  },
  "mt-12",
  {
    name: "reporting",
    path: "/reporting",
    rights: [ScopeEnum.MANAGER, ScopeEnum.MODERATOR]
  },
  {
    name: "notifications",
    path: "/notifications",
    rights: [ScopeEnum.ADMIN]
  },
  {
    name: "gpt",
    path: "/gpt",
    rights: [ScopeEnum.ADMIN]
  },
  {
    name: "log",
    path: "/log",
    rights: [ScopeEnum.MANAGER, ScopeEnum.MODERATOR]
  },
  "mt-12",
  {
    name: "advertising",
    path: "/advertising",
    rights: [ScopeEnum.MANAGER, ScopeEnum.MODERATOR]
  },
  {
    name: "news",
    path: "/news",
    rights: [ScopeEnum.BLOGGER, ScopeEnum.MANAGER, ScopeEnum.MODERATOR]
  },
  {
    name: "feed",
    path: "/feed",
    rights: [ScopeEnum.BLOGGER, ScopeEnum.MANAGER, ScopeEnum.MODERATOR]
  },
  {
    name: "fatture",
    path: "/fatture",
    rights: [ScopeEnum.ADMIN]
  }
];
