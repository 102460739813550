import React, { useCallback, useContext, useEffect, useState } from "react";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import { translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import NotificationIcon from "../../components/Icons/NotificationIcon";
import { Task, TaskInfo } from "../../client/api";
import EventsSocket from "../../client/Websockets/events";
import { IDType } from "../../config/types";

const NotificationsPostSend: React.FC<{
  task?: Task;
  failed?: IDType[];
}> = ({ task, failed }) => {
  const { loading, call } = useServer(true);
  const { l } = useContext(ClavaContext);
  const [taskInfo, setTaskInfo] = useState<TaskInfo>();
  const handler = useCallback(() => {
    if (task) {
      call("getTask", [task.id]).then(setTaskInfo);
    }
  }, [call, task]);
  useEffect(() => {
    if (task) {
      EventsSocket.setTaskHandler(task, handler);
    }
  }, [handler, task]);
  return (
    <>
      <ListBlockHeader title={translate("notificationPostSent", l)}
                       icon={<NotificationIcon />} />
      <LoadingBlock isLoading={loading} className="flex items-stretch justify-start">
        {!!taskInfo ? (
          <div className="relative max-w-xl bg-primary-30 p-4 border-primary border rounded-xl">
            <span className="font-semibold">{taskInfo.status}</span><br />
            <span>{taskInfo.result}</span>
          </div>
        ) : !!failed ? (
          <span className="text-red">{failed.join(", ")} {translate("failedSend", l)}</span>
        ) : null}
      </LoadingBlock>
    </>
  );
};

export default NotificationsPostSend;
