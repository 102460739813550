import { UnknownAction } from "redux";
import { AllStates } from "../constants";
import { ThunkMiddleware } from "redux-thunk";

/**
 *
 * @param object
 */
export declare type LogType =
  | "appState"
  | "req"
  | "upload"
  | "task"
  | "response"
  | "socket"
  | "firebase"
  | "redux";

function replaceArrays(object: any): any {
  if (object) {
    if (Array.isArray(object)) {
      return {
        arrayLength: object.length,
        content: object.length ? replaceArrays(object[0]) : "{}"
      };
    }
    if (typeof object === "object") {
      const retval = {};
      Object.keys(object).forEach(key => {
        // @ts-ignore
        retval[key] = replaceArrays(object[key]);
      });
      return retval;
    }
  }
  return object;
}

export const LOG_COLORS: {
  [T in LogType]: string;
} = {
  appState: "#5AA0E1",
  req: "#FFAB49",
  response: "#FFAB49",
  upload: "#2FCB80",
  task: "#85ff00",
  socket: "#990080",
  firebase: "#ff0000",
  redux: "#7200b6"
};
let logId = 0;
export const loggerSettings = {
  enabled: true
};

export declare type LogElem = {
  type: LogType;
  color: string;
  time: Date;
  msg: Record<string, any>;
  id: number;
};
const logs: LogElem[] = [];

export function addLog(type: LogType, msg: Record<string, any>) {
  if (loggerSettings.enabled) {
    logs.push({
      id: logId++,
      time: new Date(),
      msg,
      color: LOG_COLORS[type] ?? "WHITE",
      type
    });
    if (logs.length > 100) {
      logs.shift();
    }
  }
}

const logger: ThunkMiddleware<AllStates, UnknownAction> =
  () => next => action => {
    // @ts-ignore
    const a: AllActions = action;
    /* if (
      a.type === RouteActionTypes.GO_BACK ||
      a.type === RouteActionTypes.ADD_HISTORY_ELEM
    ) { */
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.log("\x1b[32m%s\x1b[0m", `Dispatch: ${a.type}`);
    }
    const msg: Record<string, any> = { Dispatch: a.type };
    if ("payload" in a) {
      const p = replaceArrays(a.payload);
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.log("\x1b[32m%s%O\x1b[0m", "Payload: ", p);
      }
      msg.Payload = p;
    }
    addLog("redux", msg);
    //   }
    // console.debug('Next state', store.getState());
    // console.groupEnd();
    /*  if (
      a.type === RouteActionTypes.GO_BACK ||
      a.type === RouteActionTypes.ADD_HISTORY_ELEM
    ) {
      if (__DEV__) {
        console.log(
          '\x1b[32m%s%O\x1b[0m',
          'New History: ',
          store.getState().route.history,
        );
      }
    } */
    return next(action);
  };

export default logger;
