import React, { useCallback, useContext, useRef, useState } from "react";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import { translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-regular-svg-icons";

const NotificationParamCreate: React.FC<{
  paramKey?: string,
  value?: string,
  onSave: (key: string, value: string, oldKey?: string) => void
}> = ({ paramKey, value, onSave }) => {
  const initialKey = useRef(paramKey);
  const { l } = useContext(ClavaContext);
  const [newKey, setNewKey] = useState(paramKey ?? "");
  const [newValue, setNewValue] = useState(value ?? "");
  const onSaveCont = useCallback(() => {
    onSave(newKey, newValue, initialKey.current);
    setNewKey("");
    setNewValue("");
  }, [newValue, newKey, onSave]);
  return (
    <div className="flex flex-row items-center mx-[-8px] my-4">
      <ClavaTextInput onChange={setNewKey}
                      value={newKey} label={translate("key", l)}
                      className="mx-2" />
      <ClavaTextInput onChange={setNewValue}
                      value={newValue} label={translate("value", l)}
                      className="mx-2" />
      <ClavaButton onClick={onSaveCont} className="mx-2 mt-6">
        <FontAwesomeIcon className="my-1" icon={faSave} /></ClavaButton>
    </div>
  );
};

export default NotificationParamCreate;