import React, {useCallback, useMemo} from "react";

const ClavaSwitchTri: React.FC<{
    disabled?: boolean;
    label?: string;
    horizontal?: boolean;
    className?: string;
    value: boolean | undefined;
    onChange: (val: boolean | undefined) => void;
}> = ({
          horizontal,
          disabled,
          label, className,
          value,
          onChange,
      }) => {
    const switchClass = useMemo(() => {
        let style =
            "rounded-full border border-primary flex flex-row items-center justify-between p-0.5 bg-transparent border-input-border dark:border-input-border-dark";

        if (disabled) {
            style += " opacity-50";
        }
        return style;
    }, [disabled]);
    const undef = useMemo(() => {
        return typeof value === 'undefined';
    }, [value]);
    const on = useMemo(() => {
        return !undef && value;
    }, [value, undef]);
    const off = useMemo(() => {
        return !undef && !value;
    }, [value, undef]);
    const onToggle = useCallback(() => {
        if (off) {
            onChange(undefined);
        } else if (on) {
            onChange(false);
        } else {
            onChange(true);
        }
    }, [onChange, on, off]);
    return (
        <button
            className={`relative flex w-full ${
                horizontal
                    ? "flex-row items-center justify-between"
                    : "flex-col items-start justify-stretch"
            } ${className}`}
            onClick={onToggle}
            type="button"
        >
            {!!label && (
                <span
                    className={`font-semibold text-sm relative ${horizontal ? "mr-2" : "mb-1"}`}
                >
          {label}
        </span>
            )}
            <div className={switchClass}>
                <div
                    className={`rounded-full flex flex-col items-center font-semibold justify-center text-xs w-9 h-9 transition-all ${
                        on
                            ? "mr-0 ml-12 bg-primary dark:bg-primary text-white" :
                            undef ? "mr-6 ml-6 border border-light-gray dark:border-light-gray-dark bg-input-bg dark:bg-input-bg-dark ml-0 mr-6" :
                                "mr-12 ml-0 bg-red dark:bg-red text-white"
                    }`}
                >

                </div>
            </div>
        </button>
    );
};

export default ClavaSwitchTri;
