/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Ad } from '../models/Ad';
import type { BlogList } from '../models/BlogList';
import type { BulkSearchResult } from '../models/BulkSearchResult';
import type { Coach } from '../models/Coach';
import type { LeagueList } from '../models/LeagueList';
import type { LocationList } from '../models/LocationList';
import type { MatchListElementList } from '../models/MatchListElementList';
import type { MatchSmall } from '../models/MatchSmall';
import type { PlayerSearchElementList } from '../models/PlayerSearchElementList';
import type { ScopeEnum } from '../models/ScopeEnum';
import type { SearchRequest } from '../models/SearchRequest';
import type { SearchResult } from '../models/SearchResult';
import type { SearchTypeEnum } from '../models/SearchTypeEnum';
import type { TeamListElementList } from '../models/TeamListElementList';
import type { TournamentList } from '../models/TournamentList';
import type { UserEssential } from '../models/UserEssential';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SearchService {
    /**
     * @deprecated
     * Search
     * @param query
     * @param length
     * @param offset
     * @returns SearchResult Successful Response
     * @throws ApiError
     */
    public static searchSearchQueryGet(
        query: string,
        length: number,
        offset: number,
    ): CancelablePromise<SearchResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/{query}',
            path: {
                'query': query,
            },
            query: {
                'length': length,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search
     * @param query
     * @param length
     * @param offset
     * @param areaOfInterestId
     * @param regionId
     * @returns SearchResult Successful Response
     * @throws ApiError
     */
    public static searchSearchAllQueryGet(
        query: string,
        length: number,
        offset: number,
        areaOfInterestId?: (number | null),
        regionId?: (number | null),
    ): CancelablePromise<SearchResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/all/{query}',
            path: {
                'query': query,
            },
            query: {
                'length': length,
                'offset': offset,
                'area_of_interest_id': areaOfInterestId,
                'region_id': regionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search Team
     * @param query
     * @param length
     * @param offset
     * @param leagueId
     * @param areaOfInterestId
     * @param active
     * @param regionId
     * @returns TeamListElementList Successful Response
     * @throws ApiError
     */
    public static searchTeamSearchTeamQueryGet(
        query: string,
        length: number,
        offset: number,
        leagueId?: (number | null),
        areaOfInterestId?: (number | null),
        active?: (boolean | null),
        regionId?: (number | null),
    ): CancelablePromise<TeamListElementList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/team/{query}',
            path: {
                'query': query,
            },
            query: {
                'length': length,
                'offset': offset,
                'league_id': leagueId,
                'area_of_interest_id': areaOfInterestId,
                'active': active,
                'region_id': regionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search League
     * @param query
     * @param length
     * @param offset
     * @param areaOfInterestId
     * @param active
     * @param regionId
     * @returns LeagueList Successful Response
     * @throws ApiError
     */
    public static searchLeagueSearchLeagueQueryGet(
        query: string,
        length: number,
        offset: number,
        areaOfInterestId?: number,
        active?: (boolean | null),
        regionId?: (number | null),
    ): CancelablePromise<LeagueList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/league/{query}',
            path: {
                'query': query,
            },
            query: {
                'length': length,
                'offset': offset,
                'area_of_interest_id': areaOfInterestId,
                'active': active,
                'region_id': regionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search Player
     * @param query
     * @param length
     * @param offset
     * @param noTeam
     * @param areaOfInterestId
     * @param regionId
     * @returns PlayerSearchElementList Successful Response
     * @throws ApiError
     */
    public static searchPlayerSearchPlayerQueryGet(
        query: string,
        length: number,
        offset: number,
        noTeam: boolean = true,
        areaOfInterestId?: (number | null),
        regionId?: (number | null),
    ): CancelablePromise<PlayerSearchElementList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/player/{query}',
            path: {
                'query': query,
            },
            query: {
                'length': length,
                'offset': offset,
                'no_team': noTeam,
                'area_of_interest_id': areaOfInterestId,
                'region_id': regionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search Location
     * @param query
     * @param length
     * @param offset
     * @returns LocationList Successful Response
     * @throws ApiError
     */
    public static searchLocationSearchLocationQueryGet(
        query: string,
        length: number,
        offset: number,
    ): CancelablePromise<LocationList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/location/{query}',
            path: {
                'query': query,
            },
            query: {
                'length': length,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search Match Ids By Teams
     * @param team1Id
     * @param team2Id
     * @param length
     * @param offset
     * @param date
     * @returns MatchSmall Successful Response
     * @throws ApiError
     */
    public static searchMatchIdsByTeamsSearchMatchTeam1Team1IdTeam2Team2IdGet(
        team1Id: number,
        team2Id: number,
        length: number = 100,
        offset?: number,
        date?: (string | null),
    ): CancelablePromise<Array<MatchSmall>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/match/team_1/{team_1_id}/team_2/{team_2_id}',
            path: {
                'team_1_id': team1Id,
                'team_2_id': team2Id,
            },
            query: {
                'length': length,
                'offset': offset,
                'date': date,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search Match
     * @param query
     * @param areaOfInterestId
     * @param leagueId
     * @param teamId
     * @param matchDay
     * @param dateFrom
     * @param dateTo
     * @param length
     * @param offset
     * @param regionId
     * @param matchDays
     * @returns MatchListElementList Successful Response
     * @throws ApiError
     */
    public static searchMatchSearchMatchQueryGet(
        query: string,
        areaOfInterestId?: (number | null),
        leagueId?: (number | null),
        teamId?: (number | null),
        matchDay?: (number | null),
        dateFrom?: (string | null),
        dateTo?: (string | null),
        length: number = 100,
        offset?: number,
        regionId?: (number | null),
        matchDays?: (Array<number> | null),
    ): CancelablePromise<MatchListElementList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/match/{query}',
            path: {
                'query': query,
            },
            query: {
                'area_of_interest_id': areaOfInterestId,
                'league_id': leagueId,
                'team_id': teamId,
                'match_day': matchDay,
                'date_from': dateFrom,
                'date_to': dateTo,
                'length': length,
                'offset': offset,
                'region_id': regionId,
                'match_days': matchDays,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search User
     * @param query
     * @param length
     * @param offset
     * @param scopes
     * @param areaOfInterestIds
     * @param insidersOfTeams
     * @param isInsider
     * @param isPlayer
     * @param languages
     * @param anonymous
     * @returns UserEssential Successful Response
     * @throws ApiError
     */
    public static searchUserSearchUserQueryGet(
        query: string,
        length: number,
        offset: number,
        scopes?: (Array<ScopeEnum> | null),
        areaOfInterestIds?: (Array<number> | null),
        insidersOfTeams?: (Array<number> | null),
        isInsider?: (boolean | null),
        isPlayer?: (boolean | null),
        languages?: (Array<string> | null),
        anonymous?: (boolean | null),
    ): CancelablePromise<Array<UserEssential>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/user/{query}',
            path: {
                'query': query,
            },
            query: {
                'length': length,
                'offset': offset,
                'scopes': scopes,
                'area_of_interest_ids': areaOfInterestIds,
                'insiders_of_teams': insidersOfTeams,
                'is_insider': isInsider,
                'is_player': isPlayer,
                'languages': languages,
                'anonymous': anonymous,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search Ad
     * @param query
     * @param length
     * @param offset
     * @returns Ad Successful Response
     * @throws ApiError
     */
    public static searchAdSearchAdQueryGet(
        query: string,
        length: number,
        offset: number,
    ): CancelablePromise<Array<Ad>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/ad/{query}',
            path: {
                'query': query,
            },
            query: {
                'length': length,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search Tournament
     * @param query
     * @param length
     * @param offset
     * @param cup
     * @returns TournamentList Successful Response
     * @throws ApiError
     */
    public static searchTournamentSearchTournamentQueryGet(
        query: string,
        length: number,
        offset: number,
        cup?: (boolean | null),
    ): CancelablePromise<TournamentList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/tournament/{query}',
            path: {
                'query': query,
            },
            query: {
                'length': length,
                'offset': offset,
                'cup': cup,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search Blog
     * @param query
     * @param length
     * @param offset
     * @param deleted
     * @param areaOfInterestId
     * @returns BlogList Successful Response
     * @throws ApiError
     */
    public static searchBlogSearchBlogQueryGet(
        query: string,
        length: number,
        offset: number,
        deleted?: (boolean | null),
        areaOfInterestId?: (number | null),
    ): CancelablePromise<BlogList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/blog/{query}',
            path: {
                'query': query,
            },
            query: {
                'length': length,
                'offset': offset,
                'deleted': deleted,
                'area_of_interest_id': areaOfInterestId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Search
     * @param searchType
     * @param requestBody
     * @returns BulkSearchResult Successful Response
     * @throws ApiError
     */
    public static bulkSearchSearchBulkPost(
        searchType: SearchTypeEnum,
        requestBody: Array<SearchRequest>,
    ): CancelablePromise<Array<BulkSearchResult>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/search/bulk',
            query: {
                'search_type': searchType,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search Coach
     * @param query
     * @param limit
     * @param offset
     * @param areaOfInterestId
     * @returns Coach Successful Response
     * @throws ApiError
     */
    public static searchCoachSearchCoachQueryGet(
        query: string,
        limit: number,
        offset: number,
        areaOfInterestId: number,
    ): CancelablePromise<Array<Coach>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/coach/{query}',
            path: {
                'query': query,
            },
            query: {
                'limit': limit,
                'offset': offset,
                'area_of_interest_id': areaOfInterestId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
