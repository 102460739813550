/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum MatchType {
    MATCH = 'MATCH',
    STAGE = 'STAGE',
    GROUP = 'GROUP',
    LEAGUE = 'LEAGUE',
    TOURNAMENT = 'TOURNAMENT',
}
