/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Batch } from '../models/Batch';
import type { GptMatchList } from '../models/GptMatchList';
import type { ManualRequest } from '../models/ManualRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class GptService {
    /**
     * Get User Content Team Facts
     * @param teamId
     * @param matchDay
     * @param leagueId
     * @param amountMatches
     * @returns GptMatchList Successful Response
     * @throws ApiError
     */
    public static getUserContentTeamFactsGptUserContentTeamFactsTeamIdGet(
        teamId: number,
        matchDay: number,
        leagueId: number,
        amountMatches: number,
    ): CancelablePromise<GptMatchList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/user_content_team_facts/{team_id}',
            path: {
                'team_id': teamId,
            },
            query: {
                'match_day': matchDay,
                'league_id': leagueId,
                'amount_matches': amountMatches,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Developer Content Team Facts
     * @param teamId
     * @param amountMatches
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getDeveloperContentTeamFactsGptDeveloperContentTeamFactsTeamIdGet(
        teamId: number,
        amountMatches: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/developer_content_team_facts/{team_id}',
            path: {
                'team_id': teamId,
            },
            query: {
                'amount_matches': amountMatches,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Temperature
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getTemperatureGptTemperatureGet(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/temperature',
        });
    }
    /**
     * Get Model
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getModelGptModelGet(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/model',
        });
    }
    /**
     * Get Max Tokens
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getMaxTokensGptMaxTokensGet(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/max_tokens',
        });
    }
    /**
     * Get Top P
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getTopPGptTopPGet(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/top_p',
        });
    }
    /**
     * Get Amount Matches Team Facts
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getAmountMatchesTeamFactsGptAmoutMatchesTeamFactsGet(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/amout_matches_team_facts',
        });
    }
    /**
     * Start Process Team Facts
     * @param leagueId
     * @param matchDay
     * @returns any Successful Response
     * @throws ApiError
     */
    public static startProcessTeamFactsGptTeamFactsLeagueIdMatchDayPost(
        leagueId: number,
        matchDay: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gpt/team_facts/{league_id}/{match_day}',
            path: {
                'league_id': leagueId,
                'match_day': matchDay,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Check Process Team Facts
     * @returns any Successful Response
     * @throws ApiError
     */
    public static checkProcessTeamFactsGptCheckTeamFactsPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gpt/check_team_facts',
        });
    }
    /**
     * Get Batch Status
     * @param batchId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getBatchStatusGptBatchStatusBatchIdGet(
        batchId: string,
    ): CancelablePromise<(Batch | null)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/batch_status/{batch_id}',
            path: {
                'batch_id': batchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Manual Request
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static manualRequestGptManualRequestPost(
        requestBody: ManualRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gpt/manual_request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
