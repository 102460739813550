import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";
import register from "./registerServiceWorker";

import reportWebVitals from "./reportWebVitals";
import { DropzoneProvider } from "./hooks/useDropzone";

Sentry.init({
  dsn: "https://4eff8f7450ad9ad6bae95a0bca566b02@sentry.kivi.bz.it/45",
  environment:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "development"
      : "production",
  tracesSampleRate: 0.01,
  tracePropagationTargets: ["localhost", /^https:\/\/admin\.clava-sports\.com/]

});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <DropzoneProvider>
      <App />
    </DropzoneProvider>
  </React.StrictMode>
);
register();

reportWebVitals();
