import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import ClavaButton from "../../components/Atoms/ClavaButton";
import { translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import NotificationsCreate from "./NotificationsCreate";
import { NotificationCreate } from "./types";
import { ScopeEnum, Task } from "../../client/api";
import { IDType } from "../../config/types";
import NotificationsUsers from "./NotificationsUsers";
import NotificationsReview from "./NotificationsReview";
import NotificationsPostSend from "./NotificationsPostSend";

const NotificationsPage: React.FC = () => {
  const [step, setStep] = useState<"create" | "users" | "review" | "post">("create");
  const { l } = useContext(ClavaContext);
  const [notification, setNotification] = useState<NotificationCreate>({
    title: {
      textDE: "",
      textEN: "",
      textIT: ""
    },
    body: {
      textDE: "",
      textEN: "",
      textIT: ""
    },
    data: {},
    user_ids: []
  });
  const [scopes, setScopes] = useState<ScopeEnum[]>([]);
  const [selected, setSelected] = useState<IDType[]>([]);
  const [insiderOfTeam, setInsiderOfTeam] = useState<IDType[]>([]);
  const [registered, setRegistered] = useState<boolean>();
  const [isInsider, setIsInsider] = useState<boolean>();
  const [isPlayer, setIsPlayer] = useState<boolean>();
  const [aois, setAois] = useState<number[]>([]);
  const [langs, setLangs] = useState<string[]>([]);
  const [query, setQuery] = useState<string>("");
  const [task, setTask] = useState<Task>();
  const [failed, setFailed] = useState<IDType[]>();
  const nextDisabled = useMemo(() => {
    if (step === "create") {
      return notification.body.textDE === ""
        || notification.body.textIT === ""
        || notification.body.textEN === ""
        || notification.title.textDE === ""
        || notification.title.textIT === ""
        || notification.title.textEN === "";
    }

    return step === "post" || step === "review";
  }, [notification.body.textDE, notification.body.textEN, notification.body.textIT, notification.title.textDE, notification.title.textEN, notification.title.textIT, step]);
  const onNext = useCallback(() => {
    if (!nextDisabled) {
      setStep(st => st === "create" ? "users" : st === "users" ? "review" : "post");
    }
  }, [nextDisabled]);

  const backDisabled = useMemo(() => {
    return step === "create" || step === "post";
  }, [step]);
  const onBack = useCallback(() => {
    if (!backDisabled) {
      setStep(st => st === "review" ? "users" : "create");
    }
  }, [backDisabled]);
  useEffect(() => {
    if (step !== "post") {
      if (task || failed) {
        setStep("post");
      }
    }
  }, [failed, step, task]);
  return (
    <>
      <Breadcrumps>
        {step !== "create" && (
          <ClavaButton onClick={onBack} disabled={backDisabled}
                       className="mr-4">{translate("back", l)}</ClavaButton>)}
        <ClavaButton onClick={onNext}
                     disabled={nextDisabled}>{translate("continue", l)}</ClavaButton>
      </Breadcrumps>
      <RoundedBlock
        className={`mb-2 overflow-hidden transition-all ${step === "create" ? "max-h-max" : "max-h-20"}`}>
        <NotificationsCreate notification={notification} setNotification={setNotification} />
      </RoundedBlock>
      <RoundedBlock
        className={`mb-2 overflow-hidden transition-all ${step === "users" ? "max-h-max" : "max-h-20"}`}>
        <NotificationsUsers
          active={step === "users"}
          isPlayer={isPlayer}
          langs={langs}
          isInsider={isInsider}
          scopes={scopes}
          insiderOfTeam={insiderOfTeam}
          registered={registered}
          aois={aois}
          setIsPlayer={setIsPlayer}
          setLangs={setLangs}
          setRegistered={setRegistered}
          setIsInsider={setIsInsider}
          setInsiderOfTeam={setInsiderOfTeam}
          setAois={setAois}
          setQuery={setQuery}
          setScopes={setScopes}
          setSelected={setSelected} />
      </RoundedBlock>
      <RoundedBlock
        className={`mb-2 overflow-hidden transition-all ${step === "review" ? "max-h-max" : "max-h-20"}`}>
        <NotificationsReview
          notification={notification}
          selected={selected}
          setTask={setTask}
          setFailed={setFailed}
          isPlayer={isPlayer}
          query={query}
          langs={langs}
          isInsider={isInsider}
          scopes={scopes}
          insiderOfTeam={insiderOfTeam}
          registered={registered}
          aois={aois} />
      </RoundedBlock>
      <RoundedBlock
        className={`mb-2 overflow-hidden transition-all ${step === "post" ? "max-h-max" : "max-h-20"}`}>
        <NotificationsPostSend
          task={task} failed={failed} />
      </RoundedBlock>
    </>
  );
};

export default NotificationsPage;
