import React, {useContext} from "react";
import {translate, TranslatorKeys} from "../../config/translator";
import {ClavaContext} from "../../config/contexts";
import {faSearch} from "@fortawesome/pro-light-svg-icons";
import ClavaTextInput from "../Atoms/ClavaTextInput";

const ListBlockHeader: React.FC<React.PropsWithChildren<{
    title: TranslatorKeys | string,
    onSearch?: (text: string) => void,
    searchTitle?: boolean,
    q?: string,
    icon?: React.ReactNode
}>> = ({icon, onSearch, q, title, searchTitle, children}) => {
    const {l} = useContext(ClavaContext);

    return (
        <div
            className="p-4 m-[-16px] min-h-20 mb-4 border-b border-b-light-gray dark:border-b-light-gray-dark flex flex-row items-center justify-between">
            <div className="flex-1 flex flex-row items-center justify-start">
                {icon}
                <h1 className="text-xl ml-2 font-semibold">{translate(title as TranslatorKeys, l)}</h1>
            </div>
            {children}
            {!!onSearch && (<div>
                <ClavaTextInput onChange={onSearch} label={searchTitle ? translate('search', l) : undefined} value={q}
                                placeholder={translate("searchNameId", l)}
                                iconL={faSearch}/>
            </div>)}
        </div>
    );
};

export default ListBlockHeader;
